import React, { FC } from 'react';

import ServicesIcon from '../icons/services';
import ProductsIcon from '../icons/products';
import SolutionsIcon from '../icons/solutions';

import { StyledBottomBar, StyledBottomBarLink } from './bottomBar.styles';

import useMedia from '../../hooks/useMedia';

import headerData from '../header/header.data';

const BottomBar: FC = () => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE', 'TABLET'].includes(resolutionType);
  const icons = [<ServicesIcon />, <ProductsIcon />, <SolutionsIcon />];

  return (
    isMobile ? (
      <StyledBottomBar>
        { icons.map((icon, iconIndex) => (
          <StyledBottomBarLink key={ headerData[iconIndex].id } href={ headerData[iconIndex].href }>{ icon } { headerData[iconIndex].text }</StyledBottomBarLink>
        )) }
      </StyledBottomBar>
    ) : null
  );

};

export default BottomBar;
