import React, { FC } from 'react';

import Section from '../section/section';
import ContactSide from './contactSide';
import ContactForm from '../contactForm/contactForm';
import ContactShape from './contactShape';

import {
  ContactWrapper,
  ContactTitle,
  ContactText,
  ContactLinkInline,
  ContactLink
} from './contact.styles';

import contactData from './contact.data';
import PhoneIcon from '../icons/phone';
import MailIcon from '../icons/mail';

const Contact: FC = () => {
  const title = contactData.title;
  const description = contactData.description;
  const phone = contactData.phone;
  const phoneText = contactData.phoneText;
  const mail = contactData.mail;
  
  return (
    <Section id="contact" mobilePadding="112px 0 0" padding="201px 0 0">
      <ContactWrapper>
        <ContactShape />
        <ContactSide>
          <ContactTitle>{ title }</ContactTitle>
          <ContactText dangerouslySetInnerHTML={{ __html: description }} />
          <ContactLinkInline>
            <PhoneIcon />
            <ContactLink href={ `tel:${phone}` }>{ phoneText }</ContactLink>
          </ContactLinkInline>
          <ContactLinkInline>
            <MailIcon />
            <ContactLink href={ `mailto:${mail}` }>{ mail }</ContactLink>
          </ContactLinkInline>
        </ContactSide>
        <ContactSide variant="withForm">
          <ContactForm />
        </ContactSide>
      </ContactWrapper>
    </Section>
  );
};

export default Contact;
