import React, { FC } from 'react';

import { OrangeEnergySideStyles } from './orangeEnergy.styles';

import { OrangeEnergyProps } from './orangeEnergy.types';

const OrangeEnergySide:FC<OrangeEnergyProps> = ({ variant, children }) => (
  <OrangeEnergySideStyles variant={ variant }>
    { children }
  </OrangeEnergySideStyles>
);

export default OrangeEnergySide;
