// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'services',
    href: '#services',
    text: 'Services',
  },
  {
    id: 'products',
    href: '#products',
    text: 'Products',
  },
  {
    id: 'solutions',
    href: '#about',
    text: 'Solutions',
  },
  { 
    id: 'contact',
    href: '#contact',
    text: 'Contact',
    isButton: true
  }
];
