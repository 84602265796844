import React, { FC } from 'react';

import { ContactSideStyles } from './contact.styles';

import { ContactProps } from './contact.types';

const ContactSide:FC<ContactProps> = ({ variant, children }) => (
  <ContactSideStyles variant={ variant }>
    { children }
  </ContactSideStyles>
);

export default ContactSide;
