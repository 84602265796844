import React, { FC } from 'react';

import { NavigationProps } from './navigation.types';

import { Nav, NavButton, NavLink } from './navigation.styles';

import useMedia from '../../hooks/useMedia';

const Navigation: FC<NavigationProps> = ({ navigationData }) => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE', 'TABLET'].includes(resolutionType);

  return (
    <Nav>
      { navigationData.map(({ id, href, text, isButton }) => (
        isButton ? (
          isMobile ? null : ( <NavButton key={ id } as="a" variant="mountainMeadow" href={ href }>{ text }</NavButton> )
        ) : (
          <NavLink key={ id } href={ href }>{ text }</NavLink>
        )
      )) }
    </Nav>
  );
};

export default Navigation;
