import styled, { css } from 'styled-components';

import { Wrapper } from '../layout/layout.styles';
import { topLeftRadius, LeafShapeStyles } from '../backgroundShape/backgroundShape.styles';

import { mediaQueries as mq } from '../../theme/media';
import { contentFont, sectionBigTitleFont } from '../../theme/fonts';

import { ContactProps } from './contact.types';

const setLeafShape = (size:string) => {
  return `
    width: ${size};
    height: ${size};
    border-radius: ${topLeftRadius(size)};
  `;
};

const ContactSideWithFormStyles = css`
  padding: 0;
  margin-top: 40px;

  ${ mq['large'] } {
    margin-top: 0;
    padding: 0;
  }
`;

export const ContactWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;

  ${ mq['large'] } {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  }
`;

export const ContactSideStyles = styled.div<Partial<ContactProps>>`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};

  ${({ variant }) => variant && ContactSideWithFormStyles};

  ${ mq['large'] } {
    padding: 0;
  }
`;

export const ContactTitle = styled.h3`
  ${sectionBigTitleFont};
`;

export const ContactText = styled.p`
  ${contentFont};
  margin: 24px 0 32px;

  ${ mq['large'] } {
    margin-bottom: 105px;
  }
`;

export const ContactLinkInline = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & svg {
    width: 24px;

    ${ mq['large'] } {
      width: 32px;
    }
  }

`;

export const ContactLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.mountainMeadow};

  ${ mq['large'] } {
    font-size: 24px;
  }
`;

export const ContactLeafShape = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.contact.shape.default.size)};
  position: absolute;
  z-index: -1;
  right: -63px;
  top: -52px;

  ${ mq['medium'] } {
    ${({ theme }) => setLeafShape(theme.sizes.contact.shape.medium.size)};
    right: auto;
    left: -440px;
    top: 136px;
  }

  ${ mq['large'] } {
    ${({ theme }) => setLeafShape(theme.sizes.contact.shape.large.size)};
    left: -335px;
    top: 0;
  }
`;
