import { css } from 'styled-components';

import { mediaQueries as mq } from './media';

export const heroTitleFont = css`
  font-size: 36px;
  line-height: 43px;
  font-weight: 700;
  
  ${mq.large} {
    font-size: 72px;
    line-height: 85px;
  }
`;

export const heroTitleLeadFont = css`
  font-size: 16px;
  line-height: 19px;
  
  ${mq.large} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const sectionBigTitleFont = css`
  font-size: 36px;
  line-height: 43px;
  font-weight: 700;

  ${mq.large} {
    font-size: 64px;
    line-height: 76px;
  }
`;

export const buttonFont = css`
  font-size: 16px;
  line-height: 19px;
`;

export const sectionTitleFont = css`
  font-size: 36px;
  line-height: 43px;
  font-weight: 700;
  
  ${mq.large} {
    font-size: 48px;
    line-height: 57px;
  }
`;

export const contentFont = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const bigContentFont = css`
  ${contentFont};
  font-weight: 500;
`;

export const cardTitleFont = css`
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
`;

export const authorFont = css`
  font-size: 14px;
  line-height: 17px;
`;

export const sectionLabelFont = css`
  ${authorFont};
  font-weight: 700;
  text-transform: uppercase;
`;

export const reviewsTextFont = css`
  font-size: 16px;
  line-height: 24px;

  ${ mq.large } {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const smallFont = css`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
`;
