import styled, { css } from 'styled-components';

import { mediaQueries as mq } from '../../theme/media';

const StickyBarLinkStyles = css`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Bubble = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: ${({ theme }) => theme.colors.mountainMeadow};
  border-radius: 50%;
`;

export const StickyBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 148px;

  ${ mq['xLarge'] } {
    width: 38px;
    top: 158px;
    right: 80px;
    z-index: 100;
  }

  & a {
    ${StickyBarLinkStyles};
  }
`;
