import { ReactIdSwiperProps } from "react-id-swiper";

export const SliderConfig:ReactIdSwiperProps = {
  slidesPerView: 1,
  spaceBetween: 0,
  slidesPerGroup: 1,
  slidesPerColumnFill: 'row',
  loopedSlides: 3,
  loop: true,
  loopFillGroupWithBlank: false,
  threshold: 10,
  rebuildOnUpdate: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
};
