import styled from 'styled-components';

import { Wrapper } from '../layout/layout.styles';
import { CloseIcon } from '../icons/close';

import { contentFont } from '../../theme/fonts';
import { mediaQueries as mq } from '../../theme/media';

export const CookiesWrapper = styled(Wrapper)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  max-width: ${({ theme }) => theme.sizes.cookies.wrapper.width};
  height: ${({ theme }) => theme.sizes.cookies.wrapper.height};
  margin: 0 auto;
  border-top-left-radius: 50px;
  background-color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const CookiesContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 60px 0 0;
  color: ${({ theme }) => theme.colors.white};

  ${ mq['medium'] } {
    padding: 0 110px 0 0;
  }
`;

export const CookiesText = styled.p`
  ${contentFont};

  strong {
    font-weight: 700;
  }
`
export const CookiesClose = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const CookieIcon = styled.img`
  margin-left: 38px;
  margin-right: 26px;
  
  ${ mq['medium'] } {
    margin-left: 68px;
    margin-right: 76px;
  }
`;
