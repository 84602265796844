import styled from 'styled-components';

import { Wrapper } from '../layout/layout.styles';
import { setLeafShape } from '../hero/hero.styles';
import {
  LeafShapeStyles, 
  RectangleShapeStyles, 
  bottomRadius,
  topRadius
} from '../backgroundShape/backgroundShape.styles';

import { mediaQueries as mq } from '../../theme/media';
import { 
  sectionBigTitleFont, 
  contentFont, 
  bigContentFont 
} from '../../theme/fonts';

import { WhatWeDoProps } from './whatWeDo.types';

const setRectShape = (width:string, height:string) => {
  return `
    width: ${width};
    height: ${height};
  `;
}

const setRectTopShape = (width:string, height:string) => {
  return `
    ${setRectShape(width, height)};
    border-radius: ${topRadius(height)};
  `;
}

const setRectBottomShape = (width:string, height:string) => {
  return `
    ${setRectShape(width, height)};
    border-radius: ${bottomRadius(height)};
  `;
}

const WhatWeDoSideWithShapesStyles = `
  max-width: 312px;
  width: 100%;

  ${ mq['large'] } {
    width: 100%;
    max-width: 412px;
    margin-left: 0;
    margin-right: 73px;
  }

  ${ mq['sLarge'] } {
    margin-right: 93px;
  }

  ${ mq['xLarge'] } {
    max-width: 488px;
  }
`;

export const WhatWeDoWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};

  ${ mq['medium'] } {
    flex-direction: row;
    align-items: flex-start;
  }

  ${ mq['large'] } {
    justify-content: center;
    padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  }
`;

export const WhatWeDoSideStyles = styled.div<Partial<WhatWeDoProps>>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${ mq['medium'] } {
    width: 50%;
  }

  ${ mq['large'] } {
    width: auto;
    margin-left: 73px;
    margin-right: 0;
  }

  ${ mq['sLarge'] } {
    margin-left: 75px;
  }

  ${({ variant }) => variant && WhatWeDoSideWithShapesStyles};
`;

export const WhatWeDoTitle = styled.h2`
  ${sectionBigTitleFont};
  margin-bottom: 25px;
  margin-top: 40px;
  
  ${ mq['medium'] } {
    margin-top: 0;
  }
`;

export const WhatWeDoText = styled.p`
  ${contentFont};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${ mq['large'] } {
    max-width: 375px;
  }
`;

export const WhatWeDoInline = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 15px;

  ${ mq['large'] } {
    margin-top: 20px;
  }
`;

export const WhatWeDoLeafText = styled.p`
  ${bigContentFont};
  max-width: 220px;
  margin-left: 22px;

  ${ mq['medium'] } {
    max-width: 255px;
  }

  ${ mq['large'] } {
    max-width: 270px;
    margin-left: 32px;
  }
`;

export const WhatWeDoLeaf1 = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.whatWeDo.leaf1.default)};
  align-self: flex-end;

  ${ mq['large'] } {
    ${({ theme }) => setLeafShape(theme.sizes.whatWeDo.leaf1.large)};
    bottom: 0;
    left: 0;
  }
`;

export const WhatWeDoLeaf2 = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.whatWeDo.leaf2.default)};
  align-self: center;
  position: absolute;
  top: 70px;
  left: 30px;
  z-index: -1;

  ${ mq['large'] } {
    ${({ theme }) => setLeafShape(theme.sizes.whatWeDo.leaf2.large)};
    top: 122px;
    left: 73px;
  }
`;

export const WhatWeDoRect1 = styled(RectangleShapeStyles)`
  ${({ theme }) => setRectBottomShape(
    theme.sizes.whatWeDo.rect1.default.width,
    theme.sizes.whatWeDo.rect1.default.height
  )};
  align-self: flex-end;
  top: -20px;
  left: -25px;
  background-color: transparent;

  ${ mq['large'] } {
    ${({ theme }) => setRectBottomShape(
      theme.sizes.whatWeDo.rect1.large.width,
      theme.sizes.whatWeDo.rect1.large.height
    )};
    top: -43px;
    left: -38px;
  }
`;

export const WhatWeDoRect2 = styled(RectangleShapeStyles)`
  ${({ theme }) => setRectTopShape(
    theme.sizes.whatWeDo.rect2.default.width,
    theme.sizes.whatWeDo.rect2.default.height
  )};
  background-color: transparent;

  ${ mq['large'] } {
    ${({ theme }) => setRectTopShape(
      theme.sizes.whatWeDo.rect2.large.width,
      theme.sizes.whatWeDo.rect2.large.height
    )};
    top: -42px;
    left: 0;
  }
`;

export const WhatWeDoRect3 = styled(RectangleShapeStyles)`
  ${({ theme }) => setRectBottomShape(
    theme.sizes.whatWeDo.rect3.default.width,
    theme.sizes.whatWeDo.rect3.default.height
  )};
  left: 93px;
  background-color: transparent;

  ${ mq['large'] } {
    ${({ theme }) => setRectBottomShape(
      theme.sizes.whatWeDo.rect3.large.width,
      theme.sizes.whatWeDo.rect3.large.height
    )};
    top: -42px;
    left: 146px;
  }
`;

export const WhatWeDoLeafContainer = styled.div`
  display: flex;
  align-self: center;
`;
