import React from 'react';

type MousePositionType = {
  x: number;
  y: number;
};

export class HeroPerspective {
  mousePositionPercent: MousePositionType = { x: 0, y: 0 };
  currentPositionPercent: MousePositionType = { x: 0, y: 0 };
  backgroundRef: React.MutableRefObject<HTMLDivElement>;
  objRef: React.MutableRefObject<HTMLImageElement>;

  constructor(ref: React.MutableRefObject<HTMLImageElement>, background: React.MutableRefObject<HTMLDivElement>) {
    this.objRef = ref;
    this.backgroundRef = background;
  }

  handleMouseMove = (e: MouseEvent): void => {
    this.mousePositionPercent = {
      x: Math.round((e.clientX * 100) / this.backgroundRef.current.clientWidth),
      y: Math.round((e.clientY * 100) / this.backgroundRef.current.clientHeight)
    };

    const mouseMove = () => {
      const direction = {
        LEFT: -(50 - (this.mousePositionPercent.x)),
        RIGHT: 0 + (this.mousePositionPercent.x / 2),
        UP: (50 - (this.mousePositionPercent.y)),
        DOWN: -(0 + (this.mousePositionPercent.y / 2))
      };

      this.currentPositionPercent.x = this.mousePositionPercent.x <= 50 ? direction.LEFT : direction.RIGHT;
      this.currentPositionPercent.y = this.mousePositionPercent.y <= 50 ? direction.UP : direction.DOWN;

      const translates = {
        x: `${(-1) * this.currentPositionPercent.x / 2.5}`,
        y: `${this.currentPositionPercent.y / 2.5}`
      };

      const rotates = {
        x: `${this.currentPositionPercent.y / 2}`,
        y: `${this.currentPositionPercent.x / 2}`
      };

      const transformations = `perspective(1500px) translateX(${translates.x}px) translateY(${translates.y}px) rotateX(${rotates.x}deg) rotateY(${rotates.y}deg)`;

      this.objRef.current.setAttribute('style', `transform: ${transformations}; filter: drop-shadow(${translates.x}px ${translates.y}px 16px hsla(228, 21%, 19%, 0.30
        ))`);
    }
    mouseMove();
  }

  startFollowing(): void {
    this.backgroundRef.current.addEventListener('mousemove', this.handleMouseMove);
  }

  stopFollowing(): void {
    this.backgroundRef.current.removeEventListener('mousemove', this.handleMouseMove);
    this.objRef.current.setAttribute('style', '');
  }
};
