import styled, { css } from 'styled-components';

import { Wrapper } from '../layout/layout.styles';

import { 
  sectionTitleFont,
  cardTitleFont,
  contentFont
} from '../../theme/fonts';

import { mediaQueries as mq } from '../../theme/media';

const ServiceCardStyle = css`
  color: ${({ theme }) => theme.colors.mountainMeadow};
  transition: color 0.4s ease;
`;

const ServiceCardHover = css`
  color: ${({ theme }) => theme.colors.white};
`;

export const ServicesTitle = styled.h3`
  ${sectionTitleFont};
  text-align: center;
  margin-bottom: 32px;

  ${ mq['large'] } {
    margin-bottom: 52px;
  }
`;

export const ServicesWrapper = styled(Wrapper)`
  display: flex;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  justify-content: space-between;
  flex-direction: column;

  ${ mq['large'] } {
    flex-direction: row;
  }
`;

export const ServiceCardFront = styled.div`
  position: relative;
  transition: opacity 0.5s ease;
  opacity: 1;
`;

export const ServiceCardBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 52px 22px 22px;
  background-color: ${({ theme }) => theme.colors.mountainMeadow};
  z-index: 0;
  opacity: 0;
  transition: opacity 0.5s ease;

  ${ mq['large'] } {
    padding: 56px 26px 49px;
  }

  ${ mq['xLarge'] } {
    padding: 58px 48px 49px;
  }
`;

export const ServiceCard = styled.article<{ variant?: 'front' | 'back' }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 52px 22px 22px;
  background-color: rgba(244, 248, 251, 0.75);
  margin-bottom: 20px;
  overflow: hidden;
  transition: color 0.4s ease;

  ${ mq['medium'] } {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 46px 36px 26px;
  }

  ${ mq['large'] } {
    width: calc(33.3% - 8px);
    flex-direction: column;
    padding: 56px 26px 49px;
    margin: 0 8px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  ${ mq['xLarge'] } {
    width: calc(33.3% - 16px);
    margin: 0 16px;
    padding: 56px 48px 49px;
  }

  &:first-of-type h4, &:last-of-type h4 {
    margin-bottom: 48px;
  }

  &:last-of-type h4 {
    white-space: nowrap;
  }

  &:hover {
    ${ServiceCardHover};

    ${ServiceCardFront} {
      opacity: 0;
    }

    ${ServiceCardBack} {
      opacity: 1;
    }
  }

  .ServiceCardIcon {
    z-index: 1;
  }

  &:hover .ServiceCardIcon, &:hover h4 {
    ${ServiceCardHover};
  }
`;

export const ServiceCardBackTitle = styled.p`
  font-weight: 700;
  margin: 80px 0 24px;
`;

export const ServiceList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ServiceListItem = styled.li`
  padding-left: 30px;
  margin-bottom: 8px;
  list-style: none;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 10px;
    height: 10px;
    border-radius: 8px;
    border-top-left-radius: 0;
  }
`;

export const ServiceCardIcon = styled.div`
  ${ServiceCardStyle};
  max-width: 100%;
  height: auto;
  margin-bottom: 40px;

  ${ mq['medium'] } {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  ${ mq['large'] } {
    display: block;
    margin-right: 0;
  }
`;

export const ServiceCardTitle = styled.h4`
  ${cardTitleFont};
  ${ServiceCardStyle};
  font-weight: 700;
  margin-bottom: 20px;
`;

export const ServiceCardText = styled.p`
  ${contentFont};
  height: 206px;
`;
