import React, { FC } from 'react';

import FooterColumns from './footerColumns';
import FooterCopy from './footerCopy';

import {
  FooterWrapper,
  FooterBackground,
  FooterContent,
  FooterShape,
} from './footer.styles';

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <FooterBackground>
        <FooterShape variant="mountainMeadow" />
        <FooterContent>
          <FooterColumns />
          <FooterCopy />
        </FooterContent>
      </FooterBackground>
    </FooterWrapper>
  );
};

export default Footer;
