import React, { FC, useState } from "react";

import SquareButton from "../button/square-button";
import IotOakControllerSide from "./iotOakControllerSide";

import useMedia from "../../hooks/useMedia";

import {
  IotOakControllerSection,
  IotOakControllerWrapper,
  IotOakControllerLabel,
  IotOakControllerTitle,
  IotOakControllerText,
  IotOakControllerSquareZoom,
  IotOakControllerSquareMain,
  IotOakControllerRibbon,
  IotOakControllerCTA,
  IotOakControllerReadMore,
  IotOakControllerReadMoreArea,
  IotOakControllerCTAHide,
  IotOakControllerButtonWrapper,
  IotOakControllerRibbonContainer,
  IotOakControllerLinkAnchor,
} from "./iotOakController.styles";

import iotOakControllerData from "./iotOakController.data";

const renderCTA = (onReadMoreClick: () => void) => (
  <IotOakControllerReadMoreArea>
    The data on the production and consumption of electricity is sent to the
    server for a further analysis and interpretation. As a result ofa long-term
    analysis of historical data, the user obtains knowledge that enables an
    effective cost optimization.
    <br />
    <br />
    <h3>
      This process is fully automated and adaptable to the requirements of
      individual and business customers.
    </h3>
    <br />
    Green Energy Controller also gains popularity among entrepreneurs and
    institutions who want to monitor and manage their energy consumption, even
    without using photovoltaic systems.
    <br />
    <br />
    <h3>Use cases in different industry sectors: </h3>
    <br />
    <ul>
      <li>Power engineering </li>
      <li>Industry</li>
      <li>Automation</li>
      <li>Metrology</li>
      <li>Transport/Logistics</li>
      <li>Healthcare</li>
      <li>Smart Home</li>
      <li>Smart City</li>
    </ul>
    <IotOakControllerCTAHide onClick={onReadMoreClick}>
      <IotOakControllerReadMore isOpen={false} />
      Hide
    </IotOakControllerCTAHide>
  </IotOakControllerReadMoreArea>
);

const IotOakController: FC = () => {
  const label = iotOakControllerData.label;
  const title = iotOakControllerData.title;
  const text = iotOakControllerData.text;
  const buttonText = iotOakControllerData.buttonText;
  const [isReadMore, setIsReadMore] = useState(false);

  const { resolutionType } = useMedia();
  const isMobile = ["MOBILE", "TABLET"].includes(resolutionType);

  const onReadMoreClick = () => {
    setIsReadMore(!isReadMore);
  };

  const onClick = (): void => {
    window.open("/info.pdf");
  };

  return (
    <>
      <IotOakControllerLinkAnchor id="greenenergycontroller" />
      <IotOakControllerSection id="products">
        <IotOakControllerWrapper>
          <IotOakControllerSide variant="withShapes">
            <IotOakControllerSquareZoom variant="ebonyClay" />
            <IotOakControllerSquareMain fillURL="/img/iot-oak-controller.webp" />
            {!isMobile && (
              <IotOakControllerRibbon
                src="/img/ribbon.webp"
                alt="best hardware ribbon"
              />
            )}
            <IotOakControllerButtonWrapper>
              <SquareButton
                as="button"
                variant="mountainMeadow"
                onClickHandler={onClick}
              >
                {buttonText}
              </SquareButton>
            </IotOakControllerButtonWrapper>
          </IotOakControllerSide>
          <IotOakControllerSide>
            <IotOakControllerLabel>{label}</IotOakControllerLabel>
            {isMobile && (
              <IotOakControllerRibbonContainer>
                <IotOakControllerRibbon
                  src="/img/ribbon.webp"
                  alt="best hardware ribbon"
                />
              </IotOakControllerRibbonContainer>
            )}
            <IotOakControllerTitle
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <IotOakControllerText dangerouslySetInnerHTML={{ __html: text }} />
            <IotOakControllerCTA onClick={onReadMoreClick}>
              <IotOakControllerReadMore isOpen={!isReadMore} />
              {isReadMore ? "Hide" : "Read more"}
            </IotOakControllerCTA>
            {isReadMore && isMobile && <>{renderCTA(onReadMoreClick)}</>}
          </IotOakControllerSide>
        </IotOakControllerWrapper>
        {isReadMore && !isMobile && <>{renderCTA(onReadMoreClick)}</>}
      </IotOakControllerSection>
    </>
  );
};

export default IotOakController;
