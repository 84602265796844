import React from "react";

import {
  LeafShape1,
  LeafShape2,
  RectShape1,
  RectShape2,
  LeafShape3,
} from "./about.styles";

const AboutShapes = () => (
  <>
    <LeafShape1 variant="ebonyClay" corner="topRight" />
    <LeafShape2 fillURL="/img/about-1.webp" corner="topRight" />
    <LeafShape3 fillURL="/img/about-5.webp" corner="topRight" />
    <RectShape1 fillURL="/img/about-4.webp" />
    <RectShape2 fillURL="/img/about-3.webp" />
  </>
);

export default AboutShapes;
