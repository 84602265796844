import styled from 'styled-components';

export const CloseIcon = styled.i`
  & {
    position: relative;
    width: 24px;
    height: 24px;
  }
  &::after,
  &::before {
    content: '';
    display: block;
    width: 31px;
    height: 2px;
    position: absolute;
    top: 11px;
    left: -3px;
    background-color: ${({ theme }) => theme.colors.mountainMeadow};
  }
  &::after {
    transform: rotate(-135deg);
  }
  &::before {
    transform: rotate(135deg);
  }
`;
