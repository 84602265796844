import React, { FC } from 'react';

const MailIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.7181 6.35791L22.0132 16.0003L31.7181 25.6428C31.8936 25.2761 32 24.8707 32 24.4378V7.56285C32 7.12991 31.8936 6.7246 31.7181 6.35791Z" fill="#12C671"/>
    <path d="M29.1875 4.75H2.81248C2.37954 4.75 1.97423 4.85644 1.60754 5.03188L14.0115 17.3733C15.1082 18.4701 16.8917 18.4701 17.9885 17.3733L30.3924 5.03188C30.0257 4.85644 29.6204 4.75 29.1875 4.75Z" fill="#12C671"/>
    <path d="M0.281875 6.35791C0.106438 6.7246 0 7.12991 0 7.56285V24.4378C0 24.8708 0.106438 25.2762 0.281875 25.6428L9.98681 16.0003L0.281875 6.35791Z" fill="#12C671"/>
    <path d="M20.6875 17.3257L19.3142 18.699C17.4867 20.5264 14.5132 20.5264 12.6857 18.699L11.3125 17.3257L1.60754 26.9681C1.97423 27.1436 2.37954 27.25 2.81248 27.25H29.1875C29.6204 27.25 30.0257 27.1436 30.3924 26.9681L20.6875 17.3257Z" fill="#12C671"/>
  </svg>
);

export default MailIcon;
