import React, { useState, useEffect } from "react";

import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import { GlobalStyle } from "./theme/global-styles";

import Layout from "./components/layout/layout";
import Hero from "./components/hero/hero";
import WhatWeDo from "./components/whatWeDo/whatWeDo";
import Partners from "./components/partners/partners";
import Services from "./components/services/services";
import IotOakController from "./components/iotOakController/iotOakController";
import Options from "./components/options/options";
import OrangeEnergy from "./components/orangeEnergy/orangeEnergy";
import CloudConnector from "./components/cloudConnector/cloudConnector";
// import MoreProjects from './components/moreProjects/moreProjects';
import Reviews from "./components/reviews/reviews";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Cookies from "./components/cookies/cookies";

import { useGTM } from "./utils/gtm";
import CookiesHandler from "./utils/Cookies";

import { COOKIES_ACCEPTED_KEY } from "./components/cookies/cookies.data";

function App() {
  const [, setIsAccepted] = useGTM(false);
  const [isCookiesBannerVisible, setIsCookiesBannerVisible] = useState(false);

  useEffect(() => {
    const isCookiePolicyAccepted = CookiesHandler.getCookie(
      COOKIES_ACCEPTED_KEY
    );

    setIsAccepted(isCookiePolicyAccepted);
    setIsCookiesBannerVisible(!isCookiePolicyAccepted);
  }, [setIsAccepted]);

  const handleCloseClick = (answer: boolean) => {
    setIsCookiesBannerVisible(false);
    setIsAccepted(answer);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Layout>
        <Hero />
        <WhatWeDo />
        <About />
        <Services />
        <Partners />
        <IotOakController />
        <Options />
        <OrangeEnergy />
        <CloudConnector />
        {/* <MoreProjects /> */}
        <Reviews />
        <Contact />
        {isCookiesBannerVisible && <Cookies onCloseClick={handleCloseClick} />}
      </Layout>
    </ThemeProvider>
  );
}

export default App;
