import React, { FC } from 'react';

import FacebookIcon from '../icons/facebook';
import LinkedinIcon from '../icons/linkedin';
import TwitterIcon from '../icons/twitter';
import Link from '../link/link';

import { Bubble, StickyBar } from './socialBar.styles';

import useMedia from '../../hooks/useMedia';

import socialBarData from './socialBar.data';

const SocialBar: FC = () => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE', 'TABLET', 'TINY_DESKTOP', 'SMALL_DESKTOP'].includes(resolutionType);
  const icons = [<FacebookIcon />, <LinkedinIcon />, <TwitterIcon />];

  return (
    isMobile ? null : (
      <StickyBar>
        { icons.map((icon, iconID) => (
          <Link key={ socialBarData[iconID].id } href={ socialBarData[iconID].href } target="_blank">
            <Bubble>
              { icon }
            </Bubble>
          </Link>
        )) }
      </StickyBar>
    )
  );
};

export default SocialBar;
