// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'services-1',
    iconID: 0,
    title: 'Microcontrollers',
    text: 'We know all of them: from tiny AVRs to powerful ESP32. Thanks to our C and FreeRTOS mastery, we can bring any solution to life. We will help you to pick your sensors and actuators, design a PCB and deliver a firmware.',
    technologies: Array<string>(
      "C/C++",
      "ESP 32",
      "FreeRTOS",
      "Micro:bit",
    )
  },
  {
    id: 'services-2',
    iconID: 0,
    title: 'Single Board Computers',
    text: 'Linux is our home and Python is our second language. Do you need your embedded devices to perform complicated work online? Or to launch your neural networks on GPU? We are here to guide you through.',
    technologies: Array<string>(
      "Linux",
      "Python",
      "Raspberry Pi",
      "Nvidia Jetson",
      "Tensorflow",
    ),
  },
  {
    id: 'services-3',
    iconID: 1,
    title: 'Internet of Things',
    text: 'We bring things online. We know how to squeeze the best out of technologies like MQTT, Elasticsearch and document databases which are used in most IoT clouds.',
    technologies: Array<string>(
      "Orange Live Objects",
      "Espressif IDF",
      "LTE-M / LoraWAN",
      "MQTT",
    )
  }
];