import React, { FC } from 'react';

import { LayoutProps } from './layout.types';
import { Wrapper } from './layout.styles';
import Header from '../header/header';
import BottomBar from '../bottomBar/bottomBar';
import SocialBar from '../socialBar/socialBar';
import Footer from '../footer/footer';

const Layout: FC<LayoutProps> = ({ children }) => (
  <Wrapper>
    <Header />
    {children}
    <SocialBar />
    <BottomBar />
    <Footer />
  </Wrapper>
);

export default Layout;
