import styled, { css } from 'styled-components';

import { Wrapper } from '../layout/layout.styles';
import { LeafShapeStyles } from '../backgroundShape/backgroundShape.styles';

import { authorFont, contentFont } from '../../theme/fonts';
import { mediaQueries as mq } from '../../theme/media';

const setLeafShape = (size:string) => {
  return `
    width: ${size};
    height: ${size};
    border-radius: 0 calc(${size} / 2);
  `;
};

const FooterSocialsLink = css`
  margin-right: 22px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const FooterSocialsTitleStyles = css`
  margin-top: 0;
  margin-bottom: 11px;

  ${ mq['large'] } {
    margin-top: 70px;
  }
`;

const FooterNavigationLinksStyles = css`
  margin-bottom: 29px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FooterLogoColumnStyles = css`
  img {
    width: 72px;
    height: 64px;
  }

  ${ mq['large'] } {
    margin-right: ${({ theme }) => theme.sizes.footer.margins.logoColumn};

    img {
      width: 108px;
      height: 96px;
    }
  }
`;

const FooterContactColumnStyles = css`
  div {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${ mq['large'] } {
    margin-right: ${({ theme }) => theme.sizes.footer.margins.contactColumn};
    margin-bottom: 0;

    div {
      margin-bottom: 45px;
    }
  }
`;

const FooterNavigationColumnStyles = css`
  margin-bottom: 35px;

  div {
    margin-bottom: 0;
  }

  ${ mq['large'] } {
    margin-bottom: 0;
  }
`;

const FooterContentBeforeStyles = css`
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: -70%;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.ebonyClay};
`;

export const FooterWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 84px;
  margin-bottom: 78px;

  ${ mq['large'] } {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 204px;
    margin-bottom: 0;
  }
`;

export const FooterBackground = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.sizes.footer.shapeWidth};
  width: 100%;
  margin: 0 auto;
  right: 0;

  ${ mq['large'] } {
    padding-left: 24px;
  }

  ${ mq['xLarge'] } {
    right: -120px;
  }

  ${ mq['xsLarge'] } {
    right: -172px;
  }
`;

export const FooterContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
  padding: 65px 60px 16px;
  height: 100%;
  border-top-left-radius: 150px;
  background-color: ${({ theme }) => theme.colors.ebonyClay};
  color: ${({ theme }) => theme.colors.white};

  ${ mq['large'] } {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    border-top-left-radius: 300px;
    padding: 176px 24px 61px 121px;
  }

  ${ mq['xLarge'] } {
    padding: 176px 172px 61px;
    
    &::before {
      ${FooterContentBeforeStyles};
    }
  }
`;

export const FooterShape = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.footer.leafSizes.default)};
  position: absolute;
  left: 24px;
  z-index: 1;

  ${ mq['large'] } {
    ${({ theme }) => setLeafShape(theme.sizes.footer.leafSizes.large)};
    left: auto;
    bottom: 173px;
    margin-left: -94px;
  }
`;

export const FooterCopyrights = styled.div`
  ${contentFont};
  margin-top: 40px;

  ${ mq['large'] } {
    margin-top: 94px;
  }
`;

export const FooterTitle = styled.p`
  font-weight: 500;
  margin-bottom: 6px;

  &.SocialsTitle {
    ${FooterSocialsTitleStyles};
  }
`;

export const FooterText = styled.p`
  line-height: 24px;
`;

export const FooterLink = styled.a`
  line-height: 24px;
  transition: color 0.4s ease;

  &.NavigationLinks {
    ${FooterNavigationLinksStyles}
  }

  &:hover {
    color: ${({ theme }) => theme.colors.mountainMeadow};
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &.LogoColumn {
    ${FooterLogoColumnStyles};
  }

  &.ContactColumn {
    ${FooterContactColumnStyles};
  }

  &.NavigationColumn {
    ${FooterNavigationColumnStyles};
  }

  ${ mq['large'] } {
    margin-bottom: 0;
  }
`;

export const FooterColumnItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${ mq['large'] } { 
    margin-bottom: 45px;
  }
`;

export const FooterSocialsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    ${FooterSocialsLink};
  }

  ${ mq['large'] } {
    justify-content: flex-start;
  }
`;

export const FooterCopyBar = styled.div`
  ${authorFont};
  font-weight: 400;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.footer.shapeWidth};
  opacity: 0.5;

  ${ mq['large'] } {
    margin-top: 53px;
    text-align: right;
  }
`;
