import React, { FC } from 'react';

const ServicesIcon1: FC = () => (
  <svg width="41" height="37" viewBox="0 0 41 37" fill="currentColor">
    <path d="M20.2726 36.942H4.5593C2.06254 36.942 0 34.9089 0 32.388V27.8611H24.8048V32.4151C24.8048 34.9089 22.7694 36.942 20.2726 36.942ZM1.24838 29.108V32.4151C1.24838 34.2313 2.74101 35.7222 4.5593 35.7222H20.2726C22.0909 35.7222 23.5835 34.2313 23.5835 32.4151V29.108H1.24838Z" fill="currentColor"/>
    <path d="M40.5181 29.108H23.5564V24.554C23.5564 22.0601 25.5918 20 28.1157 20H35.9588C38.4556 20 40.5181 22.033 40.5181 24.554V29.108ZM24.8048 27.8611H39.2697V24.554C39.2697 22.7378 37.7771 21.2469 35.9588 21.2469H28.1157C26.2974 21.2469 24.8048 22.7378 24.8048 24.554V27.8611Z" fill="currentColor"/>
    <path d="M4.93511 0H12.7782C14.9493 0 16.7133 1.76197 16.7133 3.93054V7.86108H1V3.93054C1 1.76197 2.76402 0 4.93511 0Z" fill="currentColor"/>
    <path d="M19.5593 16.108C22.056 16.108 24.1186 14.075 24.1186 11.554C24.1186 9.06014 22.0832 7 19.5593 7H15V11.554C15 14.075 17.0625 16.108 19.5593 16.108ZM19.5593 8.27404C21.3776 8.27404 22.8702 9.76493 22.8702 11.5811C22.8702 13.3973 21.3776 14.8882 19.5593 14.8882C17.741 14.8882 16.2484 13.3973 16.2484 11.5811V8.27404H19.5593Z" fill="currentColor"/>
  </svg>
);

export default ServicesIcon1;
