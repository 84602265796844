import React, { FC } from 'react';

import { 
  WhatWeDoLeaf1 as Leaf1,
  WhatWeDoLeaf2 as Leaf2,
  WhatWeDoRect1 as Rect1,
  WhatWeDoRect2 as Rect2,
  WhatWeDoRect3 as Rect3
} from './whatWeDo.styles';

const WhatWeDoShapes: FC = () => (
  <>
    <Leaf1 variant="mountainMeadow" corner="topRight" />
    <Rect1 fillURL="/img/what-we-do-1.webp" />
    <Leaf2 variant="ebonyClay" corner="topRight" />
    <Rect2 fillURL="/img/what-we-do-2.webp" />
    <Rect3 fillURL="/img/what-we-do-3.webp" />
  </>
);

export default WhatWeDoShapes;
