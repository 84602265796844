import React, { FC } from "react";

import Section from "../section/section";
import CloudBox from "../cloudBox/cloudBox";
import SecondaryButton from "../button/secondary-button";

import {
  OptionsQuestionTitle,
  OptionsQuestionWrapper,
  OptionsCloudDescription,
  OptionsCloudTitle,
  OptionsCloudIcon,
  OptionsCloudWrapper,
  OptionsCollectionWrapper,
  OptionsTitle,
  OptionsWrapper,
  OptionsOnHoverText,
} from "./options.styles";

import { optionsCollection, title, question, buttonText } from "./options.data";

const renderContent = (
  variant: string,
  icon: string,
  description: string,
  title: string
) => (
  <OptionsCloudWrapper hasDarkText={variant === "blackSqueeze2"}>
    <OptionsCloudIcon src={icon} alt={`${title} ${description}`} />
    <OptionsCloudTitle>{title}</OptionsCloudTitle>
    <OptionsCloudDescription>{description}</OptionsCloudDescription>
  </OptionsCloudWrapper>
);

const renderContentOnHover = (
  variant: string,
  icon: string,
  title: string,
  description: string,
  textAfterHover: string
) => (
  <OptionsCloudWrapper hasDarkText={variant === "blackSqueeze2"}>
    <OptionsCloudIcon src={icon} alt={`${title} ${description}`} />
    <OptionsOnHoverText dangerouslySetInnerHTML={{ __html: textAfterHover }} />
  </OptionsCloudWrapper>
);

const Options: FC = () => (
  <Section id="options">
    <OptionsWrapper>
      <OptionsTitle>{title}</OptionsTitle>
      <OptionsCollectionWrapper>
        {optionsCollection.map(
          ({
            id,
            variant,
            icon,
            title,
            description,
            priceBox,
            textAfterHover,
          }) => (
            <CloudBox
              key={id}
              variant={variant}
              priceBox={priceBox}
              contentWithHover={renderContentOnHover(
                variant,
                icon,
                title,
                description,
                textAfterHover
              )}
            >
              {renderContent(variant, icon, title, description)}
            </CloudBox>
          )
        )}
      </OptionsCollectionWrapper>
      <OptionsQuestionWrapper>
        <OptionsQuestionTitle>{question}</OptionsQuestionTitle>
        <SecondaryButton as="a" href="#contact">
          {buttonText}
        </SecondaryButton>
      </OptionsQuestionWrapper>
    </OptionsWrapper>
  </Section>
);

export default Options;
