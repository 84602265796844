import styled from "styled-components";

import { mediaQueries as mq } from "../../theme/media";
import {
  sectionTitleFont,
  contentFont,
  heroTitleLeadFont,
} from "../../theme/fonts";

import { Wrapper } from "../layout/layout.styles";

export const OptionsTitle = styled.h2`
  ${sectionTitleFont};
  max-width: 498px;
  margin-bottom: 86px;
`;

export const OptionsCollectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
  column-gap: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${mq["xLarge"]} {
    column-gap: 80px;
    overflow: unset;
  }
`;

export const OptionsCloudWrapper = styled.div<{ hasDarkText?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 287px;
  height: 100%;
  padding: 40px 0 40px 54px;
  color: ${({ theme, hasDarkText = false }) =>
    hasDarkText ? theme.colors.ebonyClay : theme.colors.white};
`;

export const OptionsCloudIcon = styled.img`
  width: 46px;
  height: 46px;
  margin-bottom: 20px;
`;

export const OptionsCloudTitle = styled.p`
  ${contentFont};
`;

export const OptionsCloudDescription = styled(OptionsCloudTitle)`
  font-weight: 700;
`;

export const OptionsQuestionWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 89px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq["xLarge"]} {
    margin-top: 150px;
  }
`;

export const OptionsQuestionTitle = styled.h3`
  ${heroTitleLeadFont};
  margin-bottom: 32px;
`;

export const OptionsWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 60px ${({ theme }) => theme.sizes.layoutPadding};

  ${mq["large"]} {
    padding: 150px 0 86px ${({ theme }) => theme.sizes.layoutPadding};
  }
`;

export const OptionsOnHoverText = styled.div`
  ${contentFont};

  & span.bold {
    font-weight: 700;
  }
`;
