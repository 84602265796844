import styled, { css } from 'styled-components';

import Slider from '../slider/slider';

import { Wrapper } from '../layout/layout.styles';
import { LinkStyle } from '../button/button.styles';

import { mediaQueries as mq } from '../../theme/media';
import { authorFont, reviewsTextFont } from '../../theme/fonts';

const ReviewsSliderContainer = css`
  max-width: ${({ theme }) => theme.sizes.reviews.slider.default};
  position: static;

  ${ mq['medium'] } {
    max-width: ${({ theme }) => theme.sizes.reviews.slider.large};
  }
`;

const ReviewsSliderWrapper = css`
  max-width: ${({ theme }) => theme.sizes.reviews.slider.default};

  ${ mq['medium'] } {
    max-width: ${({ theme }) => theme.sizes.reviews.slider.large};
  }
`;

const ReviewsSliderPagination = css`
  .swiper-pagination {
    bottom: -34px;

    ${ mq['large'] } {
      bottom: -42px;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    opacity: 1;
    border-radius: 8px 0;
    background-color: ${({ theme }) => theme.colors.linkWater};
    transition: background-color 0.5s ease;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 12px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: left 0.4s ease, transform 0.4s ease, background-color 0.5s ease;
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    background-color: ${({ theme }) => theme.colors.mountainMeadow};
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(1);
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.85);
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev,
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.65);
  }
`;

export const ReviewsWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};

  ${ mq['small'] } {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const ReviewsSlider = styled(Slider)`
  max-width: ${({ theme }) => theme.sizes.reviews.slider.default};
  margin: 0 auto;

  ${ mq['medium'] } {
    max-width: ${({ theme }) => theme.sizes.reviews.slider.large};
  }

  ${ReviewsSliderPagination};

  .swiper-container {
    ${ReviewsSliderContainer};
  }

  .swiper-wrapper {
    ${ReviewsSliderWrapper};
  }
`;

export const ReviewsContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  position: relative;

  ${ mq['large'] } {
    max-width: ${({ theme }) => theme.sizes.reviews.slider.large};
  }
`;

export const ReviewsSlide = styled.article`
  position: relative;
  width: 100%;
  padding: 64px 26px;
  background-color: rgba(244, 248, 251, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${ mq['large'] } {
    padding: 64px 124px;
    min-height: 384px;
  }
`;

export const ReviewsQuoteBox = styled.div`
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  width: 68px;
  height: 60px;
`;

export const ReviewsSlideText = styled.p`
  ${reviewsTextFont};
  text-align: center;
  opacity: 0.75;
  margin-bottom: 32px;
`;

export const ReviewsSlideAuthor = styled.p`
  ${authorFont};
  text-align: center;
  margin: 0 20px;
  text-transform: uppercase;

  & strong {
    font-weight: 500;
  }

  ${ mq['large'] } {
    margin: 0;
  }
`;

export const ReviewsSlideLink = styled(LinkStyle)`
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-top: 60px;

  & i {
    margin-left: 10px;
  }

  ${ mq['large'] } {
    justify-content: flex-end;
    margin-right: 20px;
  }
`;
