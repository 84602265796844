import React, { FC } from 'react';

import {
  FooterCopyBar,
  FooterText
} from './footer.styles';

import { copyBar } from './footer.data';

const FooterCopy: FC = () => (
  <FooterCopyBar>
    <FooterText dangerouslySetInnerHTML={{ __html: copyBar.title }} />
    <FooterText dangerouslySetInnerHTML={{ __html: copyBar.text }} />
  </FooterCopyBar>
);

export default FooterCopy;
