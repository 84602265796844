import React, { FC, useRef, useEffect } from "react";

import HeroShapes from "./heroShapes";

import { HeroPerspective } from "./heroPerspective";

import {
  HeroButton,
  HeroSide,
  HeroLead,
  HeroTitle,
  HeroWrapper,
  HeroImage,
} from "./hero.styles";

import useMedia from "../../hooks/useMedia";

import heroData from "./hero.data";

const Hero: FC = () => {
  const { resolutionType } = useMedia();
  const isMobile = ["MOBILE"].includes(resolutionType);

  const heroRef = useRef<any>(null);
  const heroImageRef = useRef<any>(null);

  const heroTitleText = heroData.heroText;
  const heroLeadText = heroData.heroLeadText;
  const heroButtonText = heroData.buttonText;

  useEffect(() => {
    if (!isMobile) {
      const heroEffect = new HeroPerspective(heroImageRef, heroRef);
      heroEffect.startFollowing();

      return () => {
        heroEffect.stopFollowing();
      };
    }
  }, [isMobile]);

  return (
    <section id="hero" ref={heroRef}>
      <HeroWrapper>
        <HeroSide>
          <HeroTitle dangerouslySetInnerHTML={{ __html: heroTitleText }} />
          <HeroLead dangerouslySetInnerHTML={{ __html: heroLeadText }} />
          {!isMobile && (
            <HeroButton as="a" href="#services">
              {heroButtonText}
            </HeroButton>
          )}
        </HeroSide>
        <HeroSide>
          <HeroShapes />
          <HeroImage
            ref={heroImageRef}
            src={
              isMobile ? "/img/hero-photo-mobile.webp" : "/img/hero-photo.webp"
            }
            alt="Embeded device"
          />
        </HeroSide>
        {isMobile && (
          <HeroButton as="a" href="#services">
            {heroButtonText}
          </HeroButton>
        )}
      </HeroWrapper>
    </section>
  );
};

export default Hero;
