import React, { FC } from 'react';

import FacebookIcon from '../icons/facebook';
import LinkedinIcon from '../icons/linkedin';
import TwitterIcon from '../icons/twitter';
import Link from '../link/link';

import { Bubble } from '../socialBar/socialBar.styles';

import socialBarData from '../socialBar/socialBar.data';
import { FooterSocialsBar } from './footer.styles';

const FooterSocials: FC = () => {
  const icons = [<FacebookIcon />, <LinkedinIcon />, <TwitterIcon />];

  return (
    <FooterSocialsBar>
      { icons.map((icon, iconID) => (
        <Link key={ socialBarData[iconID].id } href={ socialBarData[iconID].href } target="_blank">
          <Bubble>
            { icon }
          </Bubble>
        </Link>
      )) }
    </FooterSocialsBar>
  );
};

export default FooterSocials;
