import React, { FC, useState } from "react";

import CloudConnectorSide from "./cloudConnectorSide";

import * as Styles from "./cloudConnector.styles";

import cloudConnectorData from "./cloudConnector.data";

const CloudConnector: FC = () => {
  const [isReadMore, setIsReadMore] = useState(false);
  const label = cloudConnectorData.label;
  const title = cloudConnectorData.title;
  const text = cloudConnectorData.text;

  const onReadMoreClick = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Styles.Section>
      <Styles.CloudWrapper>
        <CloudConnectorSide variant="withShapes">
          <Styles.SquareZoom />
          <Styles.SquareMain fillURL="/img/hero-photo.webp" />
        </CloudConnectorSide>

        <CloudConnectorSide>
          <Styles.Label>{label}</Styles.Label>
          <Styles.Title dangerouslySetInnerHTML={{ __html: title }} />
          <Styles.Text>{text}</Styles.Text>
          <Styles.CTA onClick={onReadMoreClick}>
            <Styles.StyledArrowDown isOpen={!isReadMore} />
            {isReadMore ? "Hide" : "Read more"}
          </Styles.CTA>
        </CloudConnectorSide>
      </Styles.CloudWrapper>

      {isReadMore && (
        <Styles.ReadMoreArea>
          We believe that knowledge about IT, electronics and programming should
          be common, so that everyone can benefit both from the development of
          civilization and become part of this positive change.
          <h3>We support initiatives aimed towards digital education.</h3>
          For students of the art of programming and semiconductor design we
          have created a micro:bit Cloud Connector - a GSM extension module
          together with a set of libraries that enable its full capabilities.
          <br />
          <br />
          Using our Cloud Connector module, micro:bit computer and GSM
          connectivity all of our students can analyse measurement data in IoT
          cloud or Google Sheets in real time.
          <br />
          <br />
          With the help of our product students will be able to quickly build up
          their skills in the design and implementation of IoT systems.
          <br />
          <br />
          Thanks to the Liki Cloud Connector everyone will be able to build an
          irrigation system in a garden, measure the carbon dioxide content of
          the air in a classroom or monitor a route they take during bicycle
          trips. Everyone will also be able to better understand the world
          around them by analyzing data collected by them on an IoT cloud
          integrated with our module.
          <h3>
            The IoToak team feels satisfaction and fun building the Cloud
            Connector MVP.
          </h3>
          We are currently working on the IT platform and commercialization. We
          are looking forward to the first students to start exploring the world
          using the data collected by the micro:bit and Cloud Connector.
          <Styles.CTAHide onClick={onReadMoreClick}>
            <Styles.StyledArrowDown isOpen={false} />
            Hide
          </Styles.CTAHide>
        </Styles.ReadMoreArea>
      )}
    </Styles.Section>
  );
};

export default CloudConnector;
