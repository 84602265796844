import styled from 'styled-components';

import { ButtonProps } from './button.types';

import { buttonFont } from '../../theme/fonts';

const PrimaryButtonShadow = `
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  box-shadow: 0 4px 0 0 rgba(18, 198, 113, 0);
  transition: box-shadow 350ms ease;
`;

const PrimaryButtonShadowHover = `
  box-shadow: 0 4px 30px 0 rgba(18, 198, 113, 0.35);
`;

const SquareButtonLinkFont = `
  font-weight: 500;
`;

export const BasicButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  ${ buttonFont };

  &:focus {
    outline: none;
  }
`;

export const PrimaryStyle = styled(BasicButton)`
  width: 198px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.mountainMeadow};
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  &::before {
    ${PrimaryButtonShadow};
  }

  &:hover::before {
    ${PrimaryButtonShadowHover};
  }
`;

export const SecondaryStyle = styled(BasicButton)<Partial<ButtonProps>>`
  width: 182px;
  height: 48px;
  background-color: transparent;
  color: ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};
  transition: background-color 500ms ease, color 500ms ease;
  border: 2px solid ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};

  &:hover {
    background-color: ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};
    color: ${({ variant, theme }) => variant === 'white' ? theme.colors.mountainMeadow : theme.colors.white};
  }

  &:active {
    background-color: ${({ variant = 'mountainMeadow', theme }) => variant === 'mountainMeadow' ? theme.colors.persianGreen : theme.colors.white };
    color: ${({ variant, theme }) => variant === 'white' ? theme.colors.mountainMeadow : theme.colors.white};
  }
  
  ${({ theme, disabled }) => disabled && `
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: transparent;
      color: ${theme.colors.white};
    }
  `}
`;

export const SquareStyle = styled(BasicButton)<Partial<ButtonProps>>`
  width: 140px;
  height: 140px;
  transition: background-color 500ms ease, color 500ms ease;
  border: 2px solid ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};
  color: ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};
  flex-direction: column;

  & i {
    margin: 10px auto 0;
  }

  &:hover {
    background-color: ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};
    color: ${({ theme }) => theme.colors.white};
  }

  &:active {
    background-color: ${({ variant = 'mountainMeadow', theme }) => variant === 'mountainMeadow' ? theme.colors.persianGreen : theme.colors.grenadier };
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ href }) => href && SquareButtonLinkFont};
`;

export const LinkStyle = styled(BasicButton)<Partial<ButtonProps>>`
  display: inline-flex;
  color: ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};

  & i {
    margin-left: 20px;
    transition: transform 500ms ease;
  }

  &:hover i {
    transform: translateX(10px);
  }
`;
