import React, { FC } from 'react';

import { SquareStyle as Button } from './button.styles';
import { ButtonProps } from './button.types';

import { StyledArrowRight } from '../icons/arrow';

const SquareButton: FC<ButtonProps> = ({ 
  children,
  variant,
  as,
  href,
  onClickHandler
 }) => (
  <Button as={ as } href={ href } variant={ variant } onClick={ onClickHandler }>
    { children }
    <StyledArrowRight />
  </Button>
);

export default SquareButton;
