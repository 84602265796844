import { CloudBoxProps } from "../cloudBox/cloudBox.types";

type OptionsCollectionType = Array<{
  id: string;
  icon: string;
  variant: CloudBoxProps["variant"];
  priceBox: CloudBoxProps["priceBox"];
  title: string;
  description: string;
  textAfterHover: string;
}>;

export const optionsCollection: OptionsCollectionType = [
  {
    id: "option-1",
    icon: "/svg/orange-logo.svg",
    variant: "blackSqueeze2",
    title: "Controller",
    description: "+ Orange Live Objects",
    priceBox: {
      price: "660 €",
      addition: "+ Orange Live Objects",
      subscription: "200 € / 10 years",
    },
    textAfterHover:
      'Join and measure Your energy savings with <span class="bold">Orange Smart Energy Controller</span>',
  },
  {
    id: "option-2",
    icon: "/svg/connection-icon.svg",
    variant: "mountainMeadow",
    title: "Controller",
    description: "+ Cloud Connection",
    priceBox: {
      price: "660 €",
      addition: "+ Amazon Cloud",
      subscription: "200 € / 1 year",
    },
    textAfterHover:
      'Install and measure in Your network <span class="bold">Green Energy Controller</span> and connect with Your IoT cloud',
  },
  {
    id: "option-3",
    icon: "/svg/without-connection-icon.svg",
    variant: "ebonyClay",
    title: "Controller",
    description: "without connection",
    priceBox: {
      price: "660 €",
    },
    textAfterHover:
      'Just buy our <span class="bold">Green Energy Controller</span> and install to measure savings',
  },
];

export const title = "Choose between these options:";
export const question = "Interested in one of these offers?";
export const buttonText = "Contact us";
