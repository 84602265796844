import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};

  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: ${theme.colors.ebonyClay};
  }
  
  button {
    font-family: 'Rubik', sans-serif;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
