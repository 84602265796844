import React, { FC } from 'react';

import Section from '../section/section';
import ServicesIcon1 from '../icons/services-1';
import ServicesIcon2 from '../icons/services-2';

import { 
  ServicesWrapper,
  ServicesTitle,
  ServiceCard,
  ServiceCardFront,
  ServiceCardBack,
  ServiceCardIcon,
  ServiceCardTitle,
  ServiceCardText,
  ServiceCardBackTitle,
  ServiceList,
  ServiceListItem
} from './services.styles';

import servicesData from './services.data';

const Services: FC = () => {
  const icons = [<ServicesIcon1 />, <ServicesIcon2 />];

  return (
    <Section id="services" mobilePadding="0 0 64px" padding="0 0 153px">
      <ServicesTitle>Services</ServicesTitle>
      <ServicesWrapper>
        { servicesData.map((service) => (
          <ServiceCard key={ service.id }>
            <ServiceCardIcon className="ServiceCardIcon">{ icons[service.iconID] }</ServiceCardIcon>
            <ServiceCardFront>
              <ServiceCardTitle>{ service.title }</ServiceCardTitle>
              <ServiceCardText>{ service.text }</ServiceCardText>
            </ServiceCardFront>
            <ServiceCardBack>
              <ServiceCardBackTitle>Our technologies</ServiceCardBackTitle>
              <ServiceList>
                { service.technologies.map((technology, technologyID:number) => (
                  <ServiceListItem key={ `technology-${technologyID}-${service.id}` }>{ technology }</ServiceListItem>
                )) }
              </ServiceList>
            </ServiceCardBack>
          </ServiceCard>
        )) }
      </ServicesWrapper>
    </Section>
  );
};

export default Services;
