export const copyrights = {
  title: '&reg; IoT Oak',
  text: 'All right reserved.'
};

export const copyBar = {
  title: '&copy; <a href="/">2020IoTOak.com</a>. All right reserved.',
  text: 'Made by <a href="/">iotaok.com</a>'
};

export const address = {
  title: 'IoT Oak',
  text: '8/10 Żeligowskiego Street<br>Łódź 90-753 Poland',
  link: 'https://www.google.com/maps/place/%C5%BBeligowskiego+8%2F10,+90-001+%C5%81%C3%B3d%C5%BA/'
};

export const contact = [
  {
    id: 'contact-sales',
    title: 'Sales',
    phoneOnSite: '+48 790 545 727',
    phone: '048790545727'
  },
  {
    id: 'contact-recruitment',
    title: 'Recruitment',
    phoneOnSite: '+48 602 592 991',
    phone: '048602592991'
  }
];

export const navigation = [
  {
    id: 'services',
    name: 'Services',
    link: '#services'
  },
  {
    id: 'products',
    name: 'Products',
    link: '#products'
  },
  {
    id: 'solutions',
    name: 'Solutions',
    link: '#about'
  }
];

export const socialsTitle = 'Socials';
