import React, { FC } from 'react';

import { SquareShapeStyles as Square } from './backgroundShape.styles';

import { ShapeProps, SquareShapeProps } from './backgroundShape.types';

const SquareShape: FC<ShapeProps & SquareShapeProps> = ({ 
  size, 
  variant, 
  top, 
  left,
  roundBy,
  position,
  fillURL
}) => (
  <Square
    size={ size }
    position={ position }
    variant={ variant }
    top={ top }
    left={ left }
    roundBy={ roundBy }
    fillURL={ fillURL } />
);

export default SquareShape;
