import React, { FC } from 'react';

import Logo from '../logo/logo';
import Navigation from '../navigation/navigation';
import Link from '../link/link';
import ContactIcon from '../icons/contact';

import { HeaderShape, HeaderWrapper, StyledHeader } from './header.styles';

import headerData from './header.data';

import useMedia from '../../hooks/useMedia';

const Header: FC = () => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE', 'TABLET'].includes(resolutionType);

  return (
    <StyledHeader>
      <HeaderShape>
        <HeaderWrapper>
          <Logo />
          { isMobile ? ( 
            <Link href="#contact">
              <ContactIcon />
            </Link>
          ) : (
            <Navigation navigationData={ headerData }/>
          ) }
        </HeaderWrapper>
      </HeaderShape>
    </StyledHeader>
  );
};

export default Header;
