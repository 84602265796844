// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'facebook-icon',
    href: 'https://www.facebook.com/IoToak-108303288112033'
  },
  {
    id: 'linkedin-icon',
    href: 'https://www.linkedin.com/company/iot-oak/'
  },
  {
    id: 'twitter-icon',
    href: 'https://twitter.com/likimobile'
  }
];
