import styled, { css } from 'styled-components';

import { Wrapper } from '../layout/layout.styles';
import { SquareShapeStyles } from '../backgroundShape/backgroundShape.styles';
import { SectionStyled } from '../section/section.styles';
import { StyledArrowRight } from '../icons/arrow';

import {
  sectionLabelFont,
  sectionBigTitleFont,
  contentFont,
  bigContentFont
} from '../../theme/fonts';

import { mediaQueries as mq } from '../../theme/media';
import { CloudConnectorProps } from './cloudConnector.types';

interface DirectionProps {
  isOpen: boolean;
}

const setRectShape = ( size:string ) => `
  width: ${size};
  height: ${size};
`;

const Accent = css`
  color: ${({ theme }) => theme.colors.mountainMeadow};
`;

const ButtonStyles = css`
  ${bigContentFont};
  background-color: transparent;
  margin: 32px auto 0;

  ${ mq['medium'] } {
    margin: 0;
    position: absolute;
    bottom: -22px;
    left: -162px;
  }

  ${ mq['large'] } {
    bottom: 55px;
    left: -173px;
  }
`;

const SideWithShapesStyles = css`
  max-width: ${({ theme }) => theme.sizes.orangeEnergy.rect2.default};
  margin: 80px auto 0;

  ${ mq['medium'] } {
    max-width: none;
    margin: 0;
  }
`;

export const Section = styled(SectionStyled)`
  padding: 32px 0;

  ${ mq['medium'] } {
    padding: 105px 0;
  }

  ${ mq['large'] } {
    padding: 200px 0 63px;
  }
`;

export const CloudWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};


  ${ mq['medium'] } {
    flex-direction: row;
  }
`;

export const SideStyles = styled.div<Partial<CloudConnectorProps>>`
  display: flex;
  flex-direction: column;
  align-items: ${({ variant }) => variant ? 'center' : 'flex-start'};
  position: relative;
  width: 100%;

  & button {
    ${ButtonStyles};
  }

  & button i {
    margin-top: 20px;
  }

  ${ mq['medium'] } {
    align-items: flex-start;
    width: auto;
  }

  ${({ variant }) => variant && SideWithShapesStyles};
`;

export const Label = styled.div`
  ${sectionLabelFont};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 230px;
  padding: 8px 15px;
  margin: 0 auto 20px;
  background-color: rgba(38, 41, 58, 0.08);

  ${ mq['medium'] } {
    margin: 0 0 20px;
  }
`;

export const Title = styled.h2`
  ${sectionBigTitleFont};
  text-align: left;

  ${ mq['medium'] } {
    max-width: 330px;
  }

  & span.accent {
    ${Accent};
  }
`;

export const Text = styled.p`
  ${contentFont};
  margin-top: 20px;

  ${ mq['medium'] } {
    max-width: 275px;
  }

  ${ mq['large'] } {
    max-width: 325px;
  }
`;

export const SquareZoom = styled(SquareShapeStyles)`
  ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.default)};
  background-color: ${({ theme }) => theme.colors.ebonyClay};
  position: absolute;
  top: -45px;
  right: -50px;
  border-top-right-radius: 80px;

  ${ mq['medium'] } {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.medium)};
    right: -70px;
  }

  ${ mq['large'] } {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.large)};
    top: -90px;
    right: -80px;
  }
`;

export const SquareMain = styled(SquareShapeStyles)`
  ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.default)};
  background-color: ${({ theme }) => theme.colors.mountainMeadow};
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
  background-size: contain;
  background-position-x: 20px;
  margin-bottom: 60px;

  ${ mq['medium'] } {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.medium)};
  }

  ${ mq['large'] } {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.large)};
  }
`;

export const CTA = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.mountainMeadow};
  margin-top: 20px;
  margin-bottom: 30px;

  ${ mq['large'] } {
    margin-bottom: 0;
  }
`;

export const CTAHide = styled(CTA)`
  justify-content: center;
  margin-top: 50px;
`;

export const ReadMoreArea = styled.div`
  max-width: 640px;
  text-align: justify;
  margin: 0 auto;
  line-height: 24px;
  padding: 0 25px;
  margin-top: 40px;

  ${ mq['large'] } {
    padding: 0;
  }

  h3 {
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 32[x];
    margin: 20px 0;
  }
`;

export const StyledArrowDown = styled(StyledArrowRight)<DirectionProps>`
  transform: ${({ isOpen }) => isOpen ? 'rotate(90deg)' : 'rotate(-90deg)'};
  margin-right: 10px;
  transition: transform 0.2s;
`;
