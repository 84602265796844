import styled, { css } from "styled-components";

import { Wrapper } from "../layout/layout.styles";
import { SquareShapeStyles } from "../backgroundShape/backgroundShape.styles";
import { SectionStyled } from "../section/section.styles";
import { StyledArrowRight } from "../icons/arrow";

import {
  sectionLabelFont,
  sectionBigTitleFont,
  contentFont,
  bigContentFont,
} from "../../theme/fonts";

import { mediaQueries as mq } from "../../theme/media";
import { IotOakControllerProps } from "./iotOakController.types";

interface DirectionProps {
  isOpen: boolean;
}

const setRectShape = (size: string) => `
  width: ${size};
  height: ${size};
`;

const IotOakControllerAccent = css`
  color: ${({ theme }) => theme.colors.mountainMeadow};
`;

const IotOakControllerButtonStyles = css`
  ${bigContentFont};
  background-color: transparent;
  margin: 32px auto 0;

  ${mq["medium"]} {
    margin: 0;
    position: absolute;
    bottom: -22px;
    right: -162px;
  }

  ${mq["large"]} {
    bottom: 55px;
    right: -183px;
  }
`;

const IotOakControllerSideWithShapesStyles = css`
  max-width: ${({ theme }) => theme.sizes.orangeEnergy.rect2.default};
  margin: 80px auto 0;

  ${mq["medium"]} {
    max-width: none;
    align-self: center;
    margin: 0;
  }
`;

export const IotOakControllerSection = styled(SectionStyled)`
  background-color: ${({ theme }) => theme.colors.blackSqueeze};
  padding: 32px 0;

  ${mq["medium"]} {
    padding: 105px 0;
  }

  ${mq["large"]} {
    padding: 203px 0 123px;
  }
`;

export const IotOakControllerWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column-reverse;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 100px ${({ theme }) => theme.sizes.layoutPadding} 50px;

  ${mq["medium"]} {
    flex-direction: row;
    margin-top: 50px;
  }

  ${mq["medium"]} {
    padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  }
`;

export const IotOakControllerSideStyles = styled.div<
  Partial<IotOakControllerProps>
>`
  display: flex;
  flex-direction: column;
  align-items: ${({ variant }) => (variant ? "center" : "flex-start")};
  position: relative;
  width: 100%;

  & button {
    ${IotOakControllerButtonStyles};
  }

  & button i {
    margin-top: 20px;
  }

  ${mq["medium"]} {
    align-items: flex-start;
    width: ${({ variant }) => (!variant ? "280px" : "auto")};
  }

  ${mq["large"]} {
    width: auto;
  }

  ${({ variant }) => variant && IotOakControllerSideWithShapesStyles};
  ${({ variant }) =>
    variant === undefined &&
    css`
      margin-top: -100px;
    `};
`;

export const IotOakControllerLabel = styled.div`
  ${sectionLabelFont};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  margin: 0 auto 30px;
  background-color: rgba(18, 198, 113, 0.08);
  color: ${({ theme }) => theme.colors.mountainMeadow};

  ${mq["medium"]} {
    margin: 0 0 20px;
  }
`;

export const IotOakControllerTitle = styled.h2`
  ${sectionBigTitleFont};
  text-align: left;

  ${mq["medium"]} {
    max-width: 330px;
  }

  & span.accent {
    ${IotOakControllerAccent};
  }
`;

export const IotOakControllerText = styled.p`
  ${contentFont};
  margin-top: 20px;

  ${mq["medium"]} {
    max-width: 275px;
  }

  ${mq["large"]} {
    max-width: 370px;
  }
`;

export const IotOakControllerSquareZoom = styled(SquareShapeStyles)`
  ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.default)};
  position: absolute;
  top: -45px;
  right: -40px;
  border-bottom-left-radius: 80px;
  border-top-right-radius: 80px;

  ${mq["medium"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.medium)};
    right: -70px;
  }

  ${mq["large"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.large)};
    top: -90px;
    right: -115px;
  }
`;

export const IotOakControllerSquareMain = styled(SquareShapeStyles)`
  ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.default)};
  background-color: transparent;
  border-top-left-radius: 90px;
  border-bottom-right-radius: 90px;

  ${mq["medium"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.medium)};
  }

  ${mq["large"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.large)};
    margin-top: 30px;
  }
`;

export const IotOakControllerRibbonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;

  ${mq["medium"]} {
    justify-content: flex-start;
  }
`;

export const IotOakControllerRibbon = styled.img`
  position: relative;
  width: 166px;
  height: 104px;

  ${mq["large"]} {
    position: absolute;
    left: 50px;
    top: -100px;
  }
`;

export const IotOakControllerReadMore = styled(
  StyledArrowRight
)<DirectionProps>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(-90deg)")};
  margin-right: 10px;
  transition: transform 0.2s;
`;

export const IotOakControllerCTA = styled.div`
  ${contentFont};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.mountainMeadow};
  margin: 20px 0 30px;

  ${mq["large"]} {
    margin-bottom: 0;
  }
`;

export const IotOakControllerCTAHide = styled(IotOakControllerCTA)`
  justify-content: center;
  margin-top: 50px;
`;

export const IotOakControllerLinkAnchor = styled.div``;

export const IotOakControllerButtonWrapper = styled.div`
  ${mq["large"]} {
    margin-top: -30px;
  }
`;

export const IotOakControllerReadMoreArea = styled.div`
  max-width: 640px;
  text-align: justify;
  margin: 0 auto;
  line-height: 24px;
  padding: 0 25px;

  ${mq["large"]} {
    padding: 0;
    margin-top: 120px;
  }

  h3 {
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 32[x];
    margin: 20px 0;
  }

  li {
    &::before {
      content: "•";
      position: relative;
      color: ${({ theme }) => theme.colors.mountainMeadow};
      margin-right: 4px;
    }
  }
`;
