import React, { forwardRef } from 'react';

import Swiper from 'react-id-swiper';

import 'swiper/swiper-bundle.css';

import SwiperCore, { Pagination } from "swiper";

import { SliderContainer } from './slider.styles';

import { SliderProps } from './slider.types';

SwiperCore.use([Pagination]);

const Slider = forwardRef<HTMLDivElement, SliderProps>(({
  params,
  children,
  ...styles
}, ref) => (
  <SliderContainer {...styles}>
    <Swiper {...params} ref={ ref }>
      { children }
    </Swiper>
  </SliderContainer>
));

export default Slider;
