import styled, { css } from "styled-components";

import { Wrapper } from "../layout/layout.styles";
import {
  LeafShapeStyles,
  RectangleShapeStyles,
} from "../backgroundShape/backgroundShape.styles";
import { setLeafShape } from "../hero/hero.styles";

import { mediaQueries as mq } from "../../theme/media";
import {
  authorFont,
  contentFont,
  sectionBigTitleFont,
} from "../../theme/fonts";
import { AboutProps } from "./about.types";

const AboutListItemBefore = css`
  ${authorFont};
  font-weight: 500;
  counter-increment: aboutList;
  content: counter(aboutList);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 33px;
  height: 33px;
  left: 0;
  color: ${({ theme }) => theme.colors.mountainMeadow};
  background-color: rgba(18, 198, 113, 0.08);
`;

const AboutSideWithShapesStyles = css`
  width: ${({ theme }) => theme.sizes.about.leaf1.default};
  margin: 0;
  align-items: center;

  ${mq["small"]} {
    width: ${({ theme }) => theme.sizes.about.leaf1.default};
    margin: 0 0 0 40px;
  }

  ${mq["medium"]} {
    width: 263px;
    align-items: center;
  }

  ${mq["large"]} {
    width: ${({ theme }) => theme.sizes.about.leaf1.large};
    margin-left: 50px;
  }

  ${mq["xxsmLarge"]} {
    margin-left: 0;
  }
`;

const setSquareShape = (size: string) => `
  width: ${size};
  height: ${size};
  border-radius: 0;
`;

export const AboutWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  align-items: center;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};

  ${mq["medium"]} {
    flex-direction: row;
    justify-content: left;
  }

  ${mq["xxsmLarge"]} {
    justify-content: space-between;
  }
`;

export const AboutSideStyles = styled.div<Partial<AboutProps>>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  ${mq["medium"]} {
    width: 50%;
  }

  ${mq["large"]} {
    width: 40%;
  }

  ${({ variant }) => variant && AboutSideWithShapesStyles};
`;

export const AboutTitle = styled.h3`
  ${sectionBigTitleFont};
  margin: -70px 0 40px;
`;

export const LeafShape1 = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.about.leaf1.default)};
  left: -7px;

  ${mq["large"]} {
    ${({ theme }) => setLeafShape(theme.sizes.about.leaf1.large)};
    left: 0;
  }
`;

export const LeafShape2 = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.about.leaf2.default)};
  top: -113px;
  left: 27px;

  ${mq["medium"]} {
    top: -106px;
    left: 75px;
  }

  ${mq["large"]} {
    ${({ theme }) => setLeafShape(theme.sizes.about.leaf2.large)};
    top: -224px;
    left: 192px;
  }

  ${mq["xsLarge"]} {
    top: -226px;
    left: 157px;
  }
`;

export const RectShape1 = styled(RectangleShapeStyles)`
  ${({ theme }) => setSquareShape(theme.sizes.about.rect1.default)};
  position: absolute;
  right: -69px;

  ${mq["medium"]} {
    right: -45px;
  }

  ${mq["large"]} {
    ${({ theme }) => setSquareShape(theme.sizes.about.rect1.large)};
    right: -160px;
  }

  ${mq["xsLarge"]} {
    right: -156px;
  }
`;

export const RectShape2 = styled(RectangleShapeStyles)`
  ${({ theme }) => setSquareShape(theme.sizes.about.rect2.default)};
  position: absolute;
  bottom: 32px;
  left: 42px;

  ${mq["medium"]} {
    bottom: 23px;
    left: 128px;
  }

  ${mq["large"]} {
    ${({ theme }) => setSquareShape(theme.sizes.about.rect2.large)};
    bottom: 66px;
    left: 224px;
  }

  ${mq["xsLarge"]} {
    bottom: 68px;
    left: 189px;
  }
`;

export const LeafShape3 = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.about.leaf3.default)};
  position: absolute;
  bottom: 46px;
  left: -70px;

  ${mq["medium"]} {
    bottom: 43px;
    left: 14px;
  }

  ${mq["large"]} {
    ${({ theme }) => setLeafShape(theme.sizes.about.leaf3.large)};
    bottom: 94px;
    left: -20px;
  }

  ${mq["xsLarge"]} {
    left: -35px;
  }
`;

export const AboutList = styled.ol`
  position: relative;
  padding-left: 0;
  counter-reset: aboutList;
  margin-bottom: 40px;
`;

export const AboutListItem = styled.li`
  position: relative;
  padding-left: 55px;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    ${AboutListItemBefore};
  }
`;

export const AboutListItemTitle = styled.h4`
  font-weight: 500;
  margin-bottom: 10px;
`;

export const AboutListItemText = styled.p`
  ${contentFont};
`;
