import styled from 'styled-components';

import { authorFont } from '../../theme/fonts';

import { StyledLink } from '../link/link.styles';

export const StyledBottomBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid rgba(154, 168, 206, 0.15);
`;

export const StyledBottomBarLink = styled(StyledLink)`
  ${ authorFont };
  display: flex;
  width: 33.3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid rgba(154, 168, 206, 0.15);

  svg {
    margin-bottom: 8px;
  }

  &:last-child {
    border-right: none;
  }
`;
