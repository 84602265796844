// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: 'review-1',
    content: 'The app developed by Liki Mobile Solutions resulted in an increase in users, and users expressed satisfaction with the app. The team is an ideal partner. They follow instructions thoroughly and find solutions to every problem they are presented with. They are flexible and dependable.',
    author: 'Dariusz Korczykowski',
    role: 'Product Manager, Orange'
  },
  {
    id: 'review-2',
    content: 'All technical requirements have been fulfilled, and the app anticipates an October 2018 launch. Liki Mobile’s communication across international borders has been smooth, and project management has been stringent and professional.',
    author: 'Guenter Huhle',
    role: 'Founder, Corevas GmbH & Co. KG',
  },
  {
    id: 'review-3',
    content: 'Liki Mobile Solutions (IoTOak) drastically sped up development time. The project management was effective. They were easy to work with and met all deadlines.',
    author: '',
    role: 'CEO, Software Company',
  }
];
