import React, { FC } from 'react';

import { RectangleShapeStyles as Rectangle } from './backgroundShape.styles';

import { ShapeProps, RectangleShapeProps } from './backgroundShape.types';

const RectangleShape: FC<ShapeProps & RectangleShapeProps> = ({
  width,
  height,
  variant, 
  top, 
  left,
  roundSide,
  position,
  fillURL 
}) => (
  <Rectangle 
    width={ width }
    height={ height }
    position={ position }
    variant={ variant }
    top={ top }
    left={ left }
    roundSide={ roundSide }
    fillURL={ fillURL } />
);

export default RectangleShape;
