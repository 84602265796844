import React, { FC } from 'react';

import SquareButton from '../button/square-button';
import OrangeEnergySide from './orangeEnergySide';

import {
  OrangeEnergySection,
  OrangeEnergyWrapper,
  OrangeEnergyLabel,
  OrangeEnergyTitle,
  OrangeEnergyText,
  OrangeEnergySquareZoom,
  OrangeEnergySquareMain
} from './orangeEnergy.styles';

import orangeEnergyData from './orangeEnergy.data';


const OrangeEnergy: FC = () => {
  const label = orangeEnergyData.label;
  const title = orangeEnergyData.title;
  const text = orangeEnergyData.text;
  const buttonText = orangeEnergyData.buttonText;

  const onClick = () => {
    window.open('/orange_info.pdf');
  }

  return (
    <OrangeEnergySection id="orangeEnergyController">
      <OrangeEnergyWrapper>
        <OrangeEnergySide>
          <OrangeEnergyLabel>{ label }</OrangeEnergyLabel>
          <OrangeEnergyTitle dangerouslySetInnerHTML={{ __html: title }} />
          <OrangeEnergyText>{ text }</OrangeEnergyText>
        </OrangeEnergySide>
        <OrangeEnergySide variant="withShapes">
          <OrangeEnergySquareZoom fillURL="/img/orange-1.webp" />
          <OrangeEnergySquareMain fillURL="/img/orange-2.webp" />
          <SquareButton as="button" variant="blazeOrange" onClickHandler={onClick}>{ buttonText }</SquareButton>
        </OrangeEnergySide>
      </OrangeEnergyWrapper>
    </OrangeEnergySection>
  );
};

export default OrangeEnergy;
