import React, { FC } from 'react';

import Section from '../section/section';
import AboutSide from './aboutSide';
import AboutShapes from './aboutShapes';

import {
  AboutWrapper,
  AboutTitle,
  AboutList,
  AboutListItem,
  AboutListItemTitle,
  AboutListItemText
} from './about.styles';

import { title, list } from './about.data';

const About: FC = () => (
  <Section id="about" mobilePadding="60px 0" padding="150px 0 70px">
    <AboutWrapper>
      <AboutSide>
        <AboutTitle dangerouslySetInnerHTML={{ __html: title }} />
        <AboutList>
          { list.map((listItem) => (
            <AboutListItem key={ listItem.id }>
              <AboutListItemTitle>{ listItem.title }</AboutListItemTitle>
              <AboutListItemText>{ listItem.description }</AboutListItemText>
            </AboutListItem>
          )) }
        </AboutList>
      </AboutSide>
      <AboutSide variant="withShapes">
        <AboutShapes />
      </AboutSide>
    </AboutWrapper>
  </Section>
);

export default About;
