import { FC } from 'react';

import { LinkProps } from './link.types';

import { StyledLink } from './link.styles';

const Link: FC<LinkProps> = ({ href, target, children }) => (
  <StyledLink href={ href } target={ target }>{ children }</StyledLink>
);

export default Link;
