import React, { FC } from 'react';

import SquareButton from '../button/square-button';
import Section from '../section/section';

import { PartnerLogo, PartnersWrapper } from './partners.styles';

import { partners, partnerButtonText } from './partners.data';

const Partners: FC = () => {
  const partnersArr = partners;

  return (
    <Section id="partners" mobilePadding="0 0 64px" padding="0 0 220px">
      <PartnersWrapper>
        { partnersArr.map((partner) => <PartnerLogo key={ partner.id } src={ partner.src } alt={ partner.alt } />) }
        <SquareButton as="a" href="#contact">{ partnerButtonText }</SquareButton>
      </PartnersWrapper>
    </Section>
  );
};

export default Partners;
