/* eslint-disable import/no-anonymous-default-export */
export default {
  colors: {
    mercury: "#E5E5E5",
    ebonyClay: "#26293A",
    mountainMeadow: "#12C671",
    mountainMeadow2: "#10AD64",
    persianGreen: "#00AF87",
    lima: "#7ED321",
    flushOrange: "#FF7900",
    blazeOrange: "#F86506",
    grenadier: "#D04400",
    white: "#FFFFFF",
    blackSqueeze: "#F4F8FB",
    blackSqueeze2: "#F7FAFC",
    pomegranate: "#F23E3D",
    manatee: "#9A9BA4",
    linkWater: "#E4EAF8",
    harp: "#F1F8F5",
  },
  sizes: {
    leftGutter: "184px",
    layoutWidth: "1100px",
    layoutPadding: "24px",
    hero: {
      leafLightSizes: {
        default: "215px",
        medium: "290px",
        large: "575px",
      },
      leafDarkSizes: {
        medium: "160px",
        large: "227px",
      },
      leafGreenSizes: {
        default: "163px",
        medium: "190px",
        large: "398px",
      },
    },
    whatWeDo: {
      leaf1: {
        default: "51px",
        large: "79px",
      },
      leaf2: {
        default: "204px",
        large: "256px",
      },
      rect1: {
        default: {
          width: "140px",
          height: "94px",
        },
        large: {
          width: "219px",
          height: "147px",
        },
      },
      rect2: {
        default: {
          width: "93px",
          height: "63px",
        },
        large: {
          width: "146px",
          height: "99px",
        },
      },
      rect3: {
        default: {
          width: "219px",
          height: "110px",
        },
        large: {
          width: "342px",
          height: "174px",
        },
      },
    },
    orangeEnergy: {
      rect1: {
        default: "154px",
        medium: "144px",
        large: "277px",
      },
      rect2: {
        default: "240px",
        medium: "244px",
        large: "428px",
      },
    },
    moreProjects: {
      sliderAlt: {
        default: "600px",
        medium: "700px",
        large: "900px",
        xLarge: "1040px",
        xsLarge: "1100px",
      },
      slider: {
        default: "700px",
        medium: "900px",
        large: "1000px",
        xLarge: "1100px",
        xsLarge: "1600px",
      },
      slide: {
        default: "285px",
        medium: "355px",
        large: "420px",
        xLarge: "520px",
      },
    },
    reviews: {
      slider: {
        default: "500px",
        large: "888px",
      },
    },
    about: {
      leaf1: {
        default: "189px",
        large: "382px",
      },
      leaf2: {
        default: "158px",
        large: "318px",
      },
      leaf3: {
        default: "100px",
        large: "192px",
      },
      rect1: {
        default: "62px",
        large: "126px",
      },
      rect2: {
        default: "63px",
        large: "126px",
      },
      rect3: {
        default: "94px",
        large: "192px",
      },
    },
    contact: {
      shape: {
        default: {
          size: "450px",
        },
        medium: {
          size: "567px",
        },
        large: {
          size: "542px",
        },
      },
    },
    footer: {
      shapeWidth: "1092px",
      leafSizes: {
        default: "72px",
        large: "183px",
      },
      margins: {
        logoColumn: "93px",
        logoColumnLeft: "158px",
        contactColumn: "141px",
      },
    },
    cookies: {
      wrapper: {
        width: "660px",
        height: "150px",
      },
    },
  },
};
