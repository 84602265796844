import React from 'react';
import * as Typo from './typography.styles';

const Typography = () => (
  <div>
    <Typo.HeroTitle>Hero title</Typo.HeroTitle>
    <Typo.HeroTitleLead>Hero title lead</Typo.HeroTitleLead>
    <Typo.SectionBigTitle>Section big title</Typo.SectionBigTitle>
    <Typo.ButtonText>Button font</Typo.ButtonText>
    <Typo.SectionTitle>Section title</Typo.SectionTitle>
    <Typo.ContentText>Content text</Typo.ContentText>
    <Typo.BigContentText>Big content text</Typo.BigContentText>
    <Typo.CardTitle>Card title</Typo.CardTitle>
    <Typo.AuthorText>Author text</Typo.AuthorText>
    <Typo.SectionLabel>Section label</Typo.SectionLabel>
    <Typo.SmallText>Small text</Typo.SmallText>
  </div>
);

export default Typography;
