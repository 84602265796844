import styled from 'styled-components';

import { 
  ShapeProps, 
  LeafShapeProps, 
  SquareShapeProps, 
  RectangleShapeProps 
} from './backgroundShape.types';

const calcRadius = ( size:string ) => `calc(${size} / 3)`;

const addBackgroundStyles = ( fillURL?:string ) => {
  return `
    background-image: url(${fillURL});
    background-size: cover;
    background-repeat: no-repeat;`;
};

export const topLeftRadius = ( size:string ) => `0 ${calcRadius(size)}`;

export const topRightRadius = ( size:string ) => `${calcRadius(size)} 0`;

const checkCorner = ( corner:string, size:string ) => corner === 'topLeft' ? topLeftRadius(size) : topRightRadius(size);

const squareRadius = ( roundBy:string ) => `border-radius: ${roundBy}`;

export const topRadius = ( height:string ) => `${calcRadius(height)} ${calcRadius(height)} 0 0`;

export const bottomRadius = ( height:string ) => `0 0 ${calcRadius(height)} ${calcRadius(height)}`;

const checkSide = ( roundSide:string, height:string ) => roundSide === 'top' ? topRadius(height) : bottomRadius(height);

const Shape = styled.div<Partial<ShapeProps>>`
  position: ${({ position = 'relative' }) => position};
  background-color: ${({ variant = 'mountainMeadow', theme }) => theme.colors[variant]};
  top: ${({ top = 'auto' }) => top};
  left: ${({ left = 'auto' }) => left};
  ${({ fillURL }) => fillURL && addBackgroundStyles(fillURL)};
`;

export const LeafShapeStyles = styled(Shape)<Partial<LeafShapeProps>>`
  width: ${({ size = '168px' }) => size};
  height: ${({ size = '168px' }) => size};
  border-radius: ${({ corner = 'topRight', size = '168px' }) => checkCorner(corner, size) };
`;

export const SquareShapeStyles = styled(Shape)<Partial<SquareShapeProps>>`
  width: ${({ size = '168px' }) => size};
  height: ${({ size = '168px' }) => size};
  ${({ roundBy }) => roundBy && squareRadius(roundBy)};
`;

export const RectangleShapeStyles = styled(Shape)<Partial<RectangleShapeProps>>`
  width: ${({ width = '146px' }) => width};
  height: ${({ height = '99px' }) => height};
  border-radius: ${({ roundSide = 'bottom', height = '99px' }) => checkSide(roundSide, height)};
`;
