import React, { FC } from 'react';

const ProductsIcon: FC = () => (
  <svg width="34" height="32" viewBox="0 0 34 32" fill="none">
    <path d="M12.4064 14.5852L16.941 13.4292L21.4756 14.5842L21.8309 9.71496L24.2396 5.60038L19.9792 3.75644L16.941 0L13.9018 3.75644L9.64148 5.60038L12.0502 9.71496L12.4064 14.5852ZM15.1254 5.36593L16.941 3.12214L18.7567 5.36689L21.4252 6.52283L19.9064 9.11758L19.6882 12.1036L16.941 11.4036L14.1938 12.1026L13.9756 9.11662L12.4568 6.52139L15.1254 5.36593Z" fill="#12C671"/>
    <path d="M0.721563 22.0159C2.59139 23.2629 4.25267 24.7847 5.656 26.5404L9.54524 31.4L8.74074 24.9664C8.32074 21.6074 6.52667 18.5155 3.81877 16.485L2.8781 15.7798L0 21.5345L0.721563 22.0159ZM3.55172 18.8247C5.21203 20.3959 6.33825 22.4872 6.7242 24.7497C5.48388 23.2931 4.07671 21.9953 2.52571 20.8767L3.55172 18.8247Z" fill="#12C671"/>
    <path d="M25.1409 24.9666L24.3364 31.4003L28.2257 26.5397C29.63 24.784 31.2903 23.2622 33.1601 22.0152L33.8807 21.5338L31.0026 15.7791L30.0619 16.4862C27.355 18.5167 25.56 21.6076 25.1409 24.9666ZM31.356 20.877C29.805 21.9955 28.3968 23.2924 27.1575 24.7499C27.5444 22.4874 28.6696 20.3961 30.3299 18.825L31.356 20.877Z" fill="#12C671"/>
    <path d="M19.8683 32H21.8321V27.8859C21.8321 23.1696 23.4943 18.5785 26.5148 14.9544L25.0055 13.6973C21.6926 17.6733 19.8683 22.7122 19.8683 27.8859V32Z" fill="#12C671"/>
    <path d="M12.0496 32H14.0134V27.8859C14.0134 22.7113 12.1891 17.6733 8.87709 13.6973L7.3678 14.9544C10.3873 18.5785 12.0496 23.1696 12.0496 27.8859V32Z" fill="#12C671"/>
    <path d="M15.959 15.3076H17.9228V17.2714H15.959V15.3076Z" fill="#12C671"/>
    <path d="M15.959 19.2351H17.9228V31.9998H15.959V19.2351Z" fill="#12C671"/>
  </svg>
);

export default ProductsIcon;
