import React, { FC } from 'react';

import { LeafShapeStyles as Leaf } from './backgroundShape.styles';

import { ShapeProps, LeafShapeProps } from './backgroundShape.types';

const LeafShape: FC<ShapeProps & LeafShapeProps> = ({ 
  size, 
  variant, 
  top, 
  left,
  corner,
  position,
  fillURL
}) => (
  <Leaf
    size={ size }
    position={ position }
    variant={ variant }
    top={ top }
    left={ left }
    corner={ corner }
    fillURL={ fillURL } />
);

export default LeafShape;
