export const partners = [
  {
    id: 'orange',
    alt: 'Orange logo',
    src: '/svg/orange-logo.svg'
  },
  {
    id: 'microbit',
    alt: 'Microbit logo',
    src: '/svg/microbit-logo.svg'
  },
  {
    id: 'liki',
    alt: 'Liki logo',
    src: '/svg/liki-logo.svg'
  }
];

export const partnerButtonText = 'Become our partner';
