import React, { FC } from 'react';

import logoSVG from '../../logo.svg';
import logoMobileSVG from '../../logo-mobile.svg';
import logoWhiteSVG from '../../logo-white.svg';

import { LogoProps } from './logo.types';

import { LogoLink, StyledLogo } from './logo.styles';

import useMedia from '../../hooks/useMedia';

const Logo: FC<LogoProps> = ({ width, height, variant }) => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE'].includes(resolutionType);

  return (
    <LogoLink href="/">
      { isMobile ? 
        <StyledLogo src={ variant ? logoWhiteSVG : logoMobileSVG } width={ width } height={ height } alt="IoT Oak Logo" /> 
        : <StyledLogo src={ variant ? logoWhiteSVG : logoSVG } width={ width } height={ height } alt="IoT Oak Logo" /> 
      }
    </LogoLink>
  );
};

export default Logo;
