import React, { FC } from 'react';

import { 
  HeroLeafLight, 
  HeroLeafDark, 
  HeroLeafGreen 
} from './hero.styles';

import useMedia from '../../hooks/useMedia';

const HeroShapes: FC = () => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE', 'TABLET'].includes(resolutionType);
  
  return (
    <>
      <HeroLeafLight variant="blackSqueeze" corner="topRight" />
      { !isMobile && <HeroLeafDark variant="ebonyClay" corner="topRight" /> }
      <HeroLeafGreen variant="mountainMeadow" corner="topRight" />
    </>
  );
};

export default HeroShapes;
