export const list = [
  {
    id: 'list-1',
    title: 'Hardware configuration',
    description: 'What challenges do you need to solve? We will help you to choose the best hardware configuration.'
  },
  {
    id: 'list-2',
    title: 'Hardware design',
    description: 'Proof of concept, prototype or a production ready design. We will guide you through the whole process.'
  },
  {
    id: 'list-3',
    title: 'Embedded programming',
    description: 'We have access to all the best practices the embedded world has to offer thanks to combining expertise in Python and C/C++ of our engineers.'
  },
  {
    id: 'list-4',
    title: 'Connected services',
    description: 'IoT OaK is a part of Liki MS group. Our team can implement an entire solution, including custom backend, web and mobile applications.'
  }
];

export const title = 'What we do?';
