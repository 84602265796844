import styled, { css } from "styled-components";

import { PrimaryStyle } from "../button/button.styles";
import { Wrapper } from "../layout/layout.styles";
import {
  LeafShapeStyles,
  topRightRadius,
} from "../backgroundShape/backgroundShape.styles";

import { mediaQueries as mq } from "../../theme/media";
import {
  bigContentFont,
  heroTitleFont,
  heroTitleLeadFont,
} from "../../theme/fonts";
import { ButtonProps } from "../button/button.types";

export const setLeafShape = (size: string) => {
  return `
    width: ${size};
    height: ${size};
    border-radius: ${topRightRadius(size)};
  `;
};

const HeroAccentStyle = css`
  color: ${({ theme }) => theme.colors.mountainMeadow};
`;

export const HeroWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 50px ${({ theme }) => theme.sizes.layoutPadding} 0;

  ${mq["small"]} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 120px ${({ theme }) => theme.sizes.layoutPadding} 0;
  }

  ${mq["large"]} {
    padding-top: 150px;
  }
`;

export const HeroTitle = styled.h1`
  ${heroTitleFont};
  margin-bottom: 24px;

  ${mq["large"]} {
    margin-bottom: 40px;
    padding-top: 100px;
  }

  & span.accent {
    ${HeroAccentStyle};
  }
`;

export const HeroButton = styled(PrimaryStyle)<Partial<ButtonProps>>`
  ${bigContentFont};
  margin: 0 auto;
  top: 0;

  ${mq["small"]} {
    margin: 0;
    top: 0;
  }
`;

export const HeroLead = styled.p`
  ${heroTitleLeadFont};
  margin-bottom: 54px;
`;

export const HeroSide = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${mq["small"]} {
    width: 50%;
  }
`;

export const HeroImage = styled.img`
  width: 380px;
  height: auto;
  position: absolute;
  top: -45px;
  left: -10px;

  ${mq["large"]} {
    display: block;
    max-width: none;
    top: 10px;
    left: -10px;
    perspective: 1500px;
    transition: 0.8s linear;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    width: auto;
  }
`;

export const HeroLeafLight = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.hero.leafLightSizes.default)};
  left: 120px;

  ${mq["medium"]} {
    ${({ theme }) => setLeafShape(theme.sizes.hero.leafLightSizes.medium)};
    top: -25px;
  }

  ${mq["large"]} {
    ${({ theme }) => setLeafShape(theme.sizes.hero.leafLightSizes.large)};
    top: 15px;
    left: 50px;
  }
`;

export const HeroLeafDark = styled(LeafShapeStyles)`
  ${mq["medium"]} {
    ${({ theme }) => setLeafShape(theme.sizes.hero.leafDarkSizes.medium)};
    position: absolute;
    top: -50px;
    left: 90px;
  }

  ${mq["large"]} {
    ${({ theme }) => setLeafShape(theme.sizes.hero.leafDarkSizes.large)};
    top: -26px;
    left: 253px;
  }
`;

export const HeroLeafGreen = styled(LeafShapeStyles)`
  ${({ theme }) => setLeafShape(theme.sizes.hero.leafGreenSizes.default)};
  top: -50px;

  ${mq["small"]} {
    position: absolute;
    top: 110px;
  }

  ${mq["medium"]} {
    ${({ theme }) => setLeafShape(theme.sizes.hero.leafGreenSizes.medium)};
  }

  ${mq["large"]} {
    ${({ theme }) => setLeafShape(theme.sizes.hero.leafGreenSizes.large)};
    position: absolute;
    top: auto;
    bottom: -74px;
    left: -108px;
  }
`;
