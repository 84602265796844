import React, { FC } from 'react';

import { SectionStyled } from './section.styles';

import { SectionProps } from './section.types';

const Section: FC<SectionProps> = ({ 
  id,
  mobilePadding,
  padding,
  children
}) => (
  <SectionStyled id={ id } mobilePadding={ mobilePadding } padding={ padding }>
    { children }
  </SectionStyled>
);

export default Section;
