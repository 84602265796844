import styled, { css } from "styled-components";

import { Wrapper } from "../layout/layout.styles";
import { SquareShapeStyles } from "../backgroundShape/backgroundShape.styles";
import { SectionStyled } from "../section/section.styles";

import {
  sectionLabelFont,
  sectionBigTitleFont,
  contentFont,
  bigContentFont,
} from "../../theme/fonts";

import { mediaQueries as mq } from "../../theme/media";
import { OrangeEnergyProps } from "./orangeEnergy.types";

const setRectShape = (size: string) => `
  width: ${size};
  height: ${size};
`;

const OrangeEnergyAccent = css`
  color: ${({ theme }) => theme.colors.blazeOrange};
`;

const OrangeEnergyButtonStyles = css`
  ${bigContentFont};
  background-color: transparent;
  margin: 32px auto 0;

  ${mq["medium"]} {
    margin: 0;
    position: absolute;
    bottom: -22px;
    left: -162px;
  }

  ${mq["large"]} {
    bottom: 55px;
    left: -173px;
  }
`;

const OrangeEnergySideWithShapesStyles = css`
  max-width: ${({ theme }) => theme.sizes.orangeEnergy.rect2.default};
  margin: 80px auto 0;

  ${mq["medium"]} {
    max-width: none;
    margin: 0;
  }
`;

export const OrangeEnergySection = styled(SectionStyled)`
  background-color: rgba(244, 248, 251, 0.75);
  padding: 32px 0;

  ${mq["medium"]} {
    padding: 105px 0;
  }

  ${mq["large"]} {
    padding: 142px 0 123px;
    margin-top: 50px;
  }
`;

export const OrangeEnergyWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};

  ${mq["medium"]} {
    flex-direction: row;
  }
`;

export const OrangeEnergySideStyles = styled.div<Partial<OrangeEnergyProps>>`
  display: flex;
  flex-direction: column;
  align-items: ${({ variant }) => (variant ? "center" : "flex-start")};
  position: relative;
  width: 100%;

  & button {
    ${OrangeEnergyButtonStyles};
  }

  & button i {
    margin-top: 20px;
  }

  ${mq["medium"]} {
    align-items: flex-start;
    width: auto;
  }

  ${({ variant }) => variant && OrangeEnergySideWithShapesStyles};
`;

export const OrangeEnergyLabel = styled.div`
  ${sectionLabelFont};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 230px;
  padding: 8px 15px;
  margin: 0 auto 20px;
  background-color: rgba(38, 41, 58, 0.08);

  ${mq["medium"]} {
    margin: 0 0 20px;
  }
`;

export const OrangeEnergyTitle = styled.h2`
  ${sectionBigTitleFont};
  text-align: left;

  ${mq["medium"]} {
    max-width: 330px;
  }

  & span.accent {
    ${OrangeEnergyAccent};
  }
`;

export const OrangeEnergyText = styled.p`
  ${contentFont};
  margin-top: 20px;

  ${mq["medium"]} {
    max-width: 275px;
  }

  ${mq["large"]} {
    max-width: 325px;
  }
`;

export const OrangeEnergySquareZoom = styled(SquareShapeStyles)`
  ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.default)};
  position: absolute;
  top: -45px;
  left: -40px;
  z-index: 1;
  border-bottom-right-radius: 80px;
  border-top-left-radius: 80px;

  ${mq["medium"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.medium)};
    left: -70px;
  }

  ${mq["large"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect1.large)};
    top: -90px;
    left: -115px;
  }
`;

export const OrangeEnergySquareMain = styled(SquareShapeStyles)`
  ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.default)};
  background-color: transparent;
  border-bottom-right-radius: 90px;

  ${mq["medium"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.medium)};
  }

  ${mq["large"]} {
    ${({ theme }) => setRectShape(theme.sizes.orangeEnergy.rect2.large)};
  }
`;
