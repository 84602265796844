import styled from 'styled-components';

export const StyledArrowRight = styled.i`
  & {
    position: relative;
    display: block;
    width: 34px;
    height: 24px;
  }
  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 3px;
  }
  &::after {
    width: 18px;
    height: 17px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    bottom: 7px;
  }
  &::before {
    width: 24px;
    height: 2px;
    bottom: 14px;
    background: currentColor;
  }
`;
