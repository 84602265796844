import React, { FC } from 'react';

import { PrimaryStyle as Button } from './button.styles';
import { ButtonProps } from "./button.types";


const PrimaryButton: FC<ButtonProps> = ({
  children,
  as,
  href,
  onClickHandler
}) => (
  <Button as={ as } href={ href } onClick={ onClickHandler }>
    { children }
  </Button>
);

export default PrimaryButton;
