import React, { FC } from 'react';

const ServicesIcon2: FC = () => (
  <svg width="41" height="37" viewBox="0 0 41 37" fill="none">
    <path d="M20.2455 -9.47199e-05L35.9588 -9.2265e-05C38.4555 -9.1875e-05 40.5181 2.03295 40.5181 4.55391L40.5181 9.08081L15.7133 9.08081L15.7133 4.5268C15.7133 2.03294 17.7487 -9.511e-05 20.2455 -9.47199e-05ZM39.2697 7.83388L39.2697 4.52681C39.2697 2.71063 37.7771 1.21973 35.9588 1.21973L20.2455 1.21973C18.4272 1.21973 16.9345 2.71062 16.9345 4.5268L16.9345 7.83388L39.2697 7.83388Z" fill="currentColor"/>
    <path d="M-2.62573e-05 7.83389L16.9617 7.83389L16.9617 12.3879C16.9617 14.8818 14.9263 16.9419 12.4024 16.9419L4.55928 16.9419C2.06252 16.9419 -2.66034e-05 14.9089 -2.64801e-05 12.3879L-2.62573e-05 7.83389ZM15.7133 9.08082L1.24836 9.08082L1.24836 12.3879C1.24836 14.2041 2.74098 15.695 4.55928 15.695L12.4024 15.695C14.2207 15.695 15.7133 14.2041 15.7133 12.3879L15.7133 9.08082Z" fill="currentColor"/>
    <path d="M35.583 36.9419L27.7399 36.9419C25.5688 36.9419 23.8048 35.1799 23.8048 33.0114L23.8048 29.0808L39.5181 29.0808L39.5181 33.0114C39.5181 35.1799 37.7541 36.9419 35.583 36.9419Z" fill="currentColor"/>
    <path d="M20.9587 20.8339C18.462 20.8339 16.3994 22.8669 16.3994 25.3879C16.3994 27.8818 18.4348 29.9419 20.9587 29.9419L25.518 29.9419L25.518 25.3879C25.518 22.8669 23.4555 20.8339 20.9587 20.8339ZM20.9587 28.6679C19.1404 28.6679 17.6478 27.177 17.6478 25.3608C17.6478 23.5446 19.1404 22.0537 20.9587 22.0537C22.777 22.0537 24.2696 23.5446 24.2696 25.3608L24.2696 28.6679L20.9587 28.6679Z" fill="currentColor"/>
  </svg>
);

export default ServicesIcon2;
