import React, { FC } from 'react';

const SolutionsIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0)">
      <path d="M25.8158 15.3243C25.8158 9.91193 21.4125 5.50854 16 5.50854C10.5876 5.50854 6.18433 9.91193 6.18433 15.3243C6.18433 18.6434 7.87896 21.7457 10.642 23.5498V26.6422C10.642 29.5965 13.0455 32 15.9999 32C18.9543 32 21.3579 29.5965 21.3579 26.6422V24.9053C21.3579 24.9048 21.3578 23.5499 21.3578 23.5499C24.1211 21.7458 25.8158 18.6436 25.8158 15.3243ZM19.4657 26.6422C19.4657 28.5532 17.911 30.1078 16 30.1078C14.089 30.1078 12.5343 28.5532 12.5343 26.6422V25.8513H19.4657V26.6422ZM19.9405 22.2001C19.6468 22.3689 19.4657 22.6818 19.4657 23.0206V23.9591H16.9461V19.0667C18.1746 18.667 19.0654 17.5116 19.0654 16.1514C19.0654 15.6289 18.6417 15.2053 18.1192 15.2053C17.5967 15.2053 17.1731 15.6289 17.1731 16.1514C17.1731 16.7983 16.6468 17.3246 16 17.3246C15.3532 17.3246 14.8269 16.7983 14.8269 16.1514C14.8269 15.6289 14.4033 15.2053 13.8808 15.2053C13.3583 15.2053 12.9347 15.6289 12.9347 16.1514C12.9347 17.5115 13.8253 18.667 15.0539 19.0667V23.9591H12.5345V23.0205C12.5345 22.6818 12.3534 22.3689 12.0597 22.2001C9.60283 20.7887 8.0767 18.1541 8.0767 15.3243C8.0767 10.9553 11.6312 7.4008 16.0002 7.4008C20.3693 7.4008 23.9237 10.9552 23.9237 15.3243C23.9236 18.1541 22.3974 20.7888 19.9405 22.2001Z" fill="#12C671"/>
      <path d="M16 0C15.4775 0 15.0538 0.423626 15.0538 0.946127V2.60707C15.0538 3.12957 15.4775 3.55319 16 3.55319C16.5225 3.55319 16.9461 3.12957 16.9461 2.60707V0.946127C16.9461 0.423626 16.5225 0 16 0Z" fill="#12C671"/>
      <path d="M13.3385 2.84967L12.7513 1.29592C12.5665 0.80717 12.0204 0.560732 11.5318 0.74542C11.043 0.93017 10.7965 1.47617 10.9813 1.96492L11.5685 3.51868C11.7116 3.8973 12.0715 4.13055 12.4537 4.13055C12.5648 4.13055 12.6779 4.1108 12.788 4.06918C13.2768 3.88443 13.5233 3.33843 13.3385 2.84967Z" fill="#12C671"/>
      <path d="M20.4681 0.745463C19.9792 0.56065 19.4333 0.807275 19.2486 1.29603L18.6613 2.84978C18.4766 3.33847 18.7231 3.88447 19.2119 4.06922C19.322 4.11084 19.4351 4.13059 19.5462 4.13059C19.9284 4.13059 20.2883 3.89734 20.4314 3.51872L21.0186 1.96496C21.2034 1.47615 20.9569 0.930213 20.4681 0.745463Z" fill="#12C671"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default SolutionsIcon;
