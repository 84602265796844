import React, { FC } from 'react';

import { SideStyles } from './cloudConnector.styles';

import { CloudConnectorProps } from './cloudConnector.types';

const CloudConnectorSide:FC<CloudConnectorProps> = ({ variant, children }) => (
  <SideStyles variant={ variant }>
    { children }
  </SideStyles>
);

export default CloudConnectorSide;
