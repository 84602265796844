import styled, { css } from "styled-components";

import { CloudBoxProps } from "./cloudBox.types";

import { mediaQueries as mq } from "../../theme/media";
import { contentFont } from "../../theme/fonts";

const CloudBoxWrapperShadow = css<{ variant?: CloudBoxProps["variant"] }>`
  &::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 80%;
    height: 80px;
    filter: blur(30px);
    opacity: 0;
    border-radius: 100%;
    transform: translateY(-20px);
    background-color: ${({ variant = "blackSqueeze2", theme }) =>
      variant === "blackSqueeze2"
        ? theme.colors.manatee
        : theme.colors[variant]};
    transition: transform 0.85s ease, opacity 0.65s ease;
    will-change: transform, opacity;
  }
`;

const CloudBoxWrapperShadowAfterHover = css`
  &::after {
    opacity: 0.25;
    transform: translateY(20px);
  }
`;

export const CloudBoxShape = styled.svg`
  position: absolute;
  z-index: -1;
`;

export const CloudBoxShapePath = styled.path<{
  variant?: CloudBoxProps["variant"];
}>`
  fill: ${({ variant = "blackSqueeze2", theme }) => theme.colors[variant]};
`;

export const CloudBoxPriceBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
`;

export const CloudBoxPriceBoxBoldPrice = styled.span`
  ${contentFont};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const CloudBoxPriceBoxAddition = styled.span`
  ${contentFont};
`;

export const CloudBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CloudBoxContentOnHover = styled.div`
  position: absolute;
  display: flex;
  align-self: flex-start;
  top: 0;
  left: 0;
  width: 85%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
`;

export const CloudBoxContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  transition: opacity 0.5s ease;
  opacity: 1;
`;

export const CloudBoxWrapper = styled.div<{
  variant?: CloudBoxProps["variant"];
}>`
  position: relative;
  width: 287px;
  height: 299px;
  transform: translateY(0);
  transition: transform 0.75s ease;
  will-change: transform;

  ${mq["xLarge"]} {
    ${CloudBoxWrapperShadow};

    &:hover {
      transform: translateY(-30px);
      ${CloudBoxWrapperShadowAfterHover};

      ${CloudBoxContent} {
        opacity: 0;
      }

      ${CloudBoxContentOnHover} {
        opacity: 1;
      }

      ${CloudBoxContent} {
        justify-self: flex-start;
      }
    }
  }
`;
