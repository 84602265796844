import React from 'react';

import PrimaryButton from '../../../components/button/primary-button';
import SecondaryButton from '../../../components/button/secondary-button';
import SquareButton from '../../../components/button/square-button';
import LinkButton from '../../../components/button/link-button';

const Buttons = () => (
  <>
    <PrimaryButton as="button" href="#primary">Primary</PrimaryButton>
    <SecondaryButton as="button" href="#secondary" variant="mountainMeadow">Secondary</SecondaryButton>
    <SecondaryButton as="button" href="#secondary2" variant="blazeOrange">Secondary</SecondaryButton>
    <SecondaryButton as="button" href="#secondary3" variant="white">Secondary</SecondaryButton>
    <SquareButton as="a" href="#square" variant="mountainMeadow">Square</SquareButton>
    <SquareButton as="a" href="#square2" variant="blazeOrange">Square</SquareButton>
    <LinkButton as="a" href="#link">Link</LinkButton>
  </>
);

export default Buttons;
