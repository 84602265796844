import styled from 'styled-components';

import { mediaQueries as mq } from '../../theme/media';

import { Wrapper } from '../layout/layout.styles';

const HeaderShapeBefore = ( theme:string ) => {
  return `
    content: "";
    width: 80%;
    height: 100%;
    border-bottom-right-radius: 100px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${theme};

    ${mq['mobileMax']} {
      width: 40%;
    }
  }`;
};

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const HeaderWrapper = styled(Wrapper)`
  display: flex;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  align-items: center;
  justify-content: space-between;
`;

export const HeaderShape = styled.div`
  width: 100%;
  padding: 20px 0;
  position: relative;
  
  &::before {
    ${({ theme }) => HeaderShapeBefore(theme.colors.ebonyClay)};
  }
`;
