import { useEffect } from 'react';

const useBitrixForm = (elementId: string, formId: string, formNumber: number): void => {
  const SCRIPT_ID = `${elementId}_script`;

  useEffect(() => {
    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.type = 'application/javascript';
    script.setAttribute('data-b24-form', formId);
    script.setAttribute('data-skip-moving', 'true');
    script.innerHTML = `(function(w,d,u){
                var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn.bitrix24.com/b9184493/crm/form/loader_${formNumber}.js');`;
    document.getElementById(elementId)?.appendChild(script);

    return () => {
      const script = document.getElementById(SCRIPT_ID);

      if (script) {
        document.getElementById(elementId)?.removeChild(script);
      }
    };
  }, [SCRIPT_ID, elementId, formId, formNumber])
};

export default useBitrixForm;
