import React, { FC } from 'react';

import { AboutSideStyles } from './about.styles';

import { AboutProps } from './about.types';

const AboutSide:FC<AboutProps> = ({ variant, children }) => (
  <AboutSideStyles variant={ variant }>
    { children }
  </AboutSideStyles>
);

export default AboutSide;
