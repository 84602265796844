import React, { FC } from 'react';

import { SecondaryStyle as Button } from './button.styles';
import { ButtonProps } from './button.types';

const SecondaryButton: FC<ButtonProps> = ({ 
  children,
  variant,
  as,
  href,
  onClickHandler,
  disabled
 }) => (
  <Button as={ as } href={ href } variant={ variant } onClick={ onClickHandler } disabled={ disabled }>
    { children }
  </Button>
);

export default SecondaryButton;
