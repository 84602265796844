export const mq = (minWidth: number) => {
  const prefix = 'min-width: ';
  const suffix = 'px';
  return `@media (${prefix}${minWidth}${suffix})`;
}

export const breakpoints = {
  xSmall: 450,
  small: 576,
  edgeSmall: 577,
  medium: 768,
  mobileMax: 991,
  large: 992,
  sLarge: 1020,
  xLarge: 1100,
  xsLarge: 1160,
  xmLarge: 1300,
  xxLarge: 1360,
  xxsmLarge: 1420,
  xxsLarge: 1600,
  xxxLarge: 1920
};

export const mediaQueries = Object.fromEntries(
  Object.entries(breakpoints).map(([key, value]) => [key, mq(value)])
);
