import styled, { css } from 'styled-components';

import { Wrapper } from '../layout/layout.styles';

import { bigContentFont } from '../../theme/fonts';
import { mediaQueries as mq } from '../../theme/media';

const partnerButtonStyle = css`
  ${bigContentFont};
  background-color: transparent;
  margin: 40px auto 0;

  & i {
    margin-top: 20px;
  }

  ${ mq['small'] } {
    margin: 0;
  }
`

export const PartnersWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.sizes.layoutWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  flex-wrap: wrap;

  & button, a {
    ${partnerButtonStyle};
  }
`;

export const PartnerLogo = styled.img`
  max-width: 100%;
  width: 74px;
  height: auto;
  margin: 0 24px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${ mq['medium'] } {
    width: auto;
  }
`;
