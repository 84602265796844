import React, { FC } from 'react';

import Logo from '../logo/logo';
import FooterSocials from './footerSocials';

import {
  FooterCopyrights,
  FooterTitle,
  FooterColumn,
  FooterColumnItem,
  FooterText,
  FooterLink
} from './footer.styles';

import { 
  copyrights,
  address,
  contact,
  navigation,
  socialsTitle
} from './footer.data';

import useMedia from '../../hooks/useMedia';

const FooterColumns: FC = () => {
  const { resolutionType } = useMedia();
  const isMobile = ['MOBILE', 'TABLET'].includes(resolutionType);
  return (
    <>
      <FooterColumn className="LogoColumn">
        <Logo variant="white" />
        <FooterCopyrights>
          <FooterTitle dangerouslySetInnerHTML={{ __html: copyrights.title }} />
          <FooterText>{ copyrights.text }</FooterText>
        </FooterCopyrights>
      </FooterColumn>
      <FooterColumn className="ContactColumn">
        { !isMobile && (<FooterColumnItem>
          <FooterTitle>{ address.title }</FooterTitle>
          <FooterLink href={ address.link } target="_blank" dangerouslySetInnerHTML={{ __html: address.text }} />
        </FooterColumnItem>) }
          { contact.map((contactData) => (
            <FooterColumnItem key={ contactData.id }>
              <FooterTitle>{ contactData.title }</FooterTitle>
              <FooterLink href={ `tel:${contactData.phone}` }>{ contactData.phoneOnSite }</FooterLink>
            </FooterColumnItem>
          )) }
      </FooterColumn>
      <FooterColumn className="NavigationColumn">
        { !isMobile && (<FooterColumnItem>
          { navigation.map((navigationItem) => (
            <FooterLink className="NavigationLinks" key={ navigationItem.id } href={ navigationItem.link }>{ navigationItem.name }</FooterLink>
          )) }
        </FooterColumnItem>) }
        <FooterColumnItem>
          <FooterTitle className="SocialsTitle">{ socialsTitle }</FooterTitle>
          <FooterSocials />
        </FooterColumnItem>
      </FooterColumn>
    </>
  );
};

export default FooterColumns;
