import React, { FC } from "react";

import Section from "../section/section";
import { StyledArrowRight } from "../icons/arrow";
import QuoteIcon from "../icons/quote";

import {
  ReviewsContainer,
  ReviewsQuoteBox,
  ReviewsSlide,
  ReviewsSlideAuthor,
  ReviewsSlider,
  ReviewsSlideText,
  ReviewsWrapper,
  ReviewsSlideLink,
} from "./reviews.styles";

import { SliderConfig } from "./reviews.config";

import reviewsData from "./reviews.data";

const Reviews: FC = () => (
  <Section id="reviews" mobilePadding="48px 0 0" padding="129px 0 0">
    <ReviewsWrapper>
      <ReviewsContainer>
        <ReviewsQuoteBox>
          <QuoteIcon />
        </ReviewsQuoteBox>
        <ReviewsSlider params={SliderConfig}>
          {reviewsData.map((review) => (
            <ReviewsSlide key={review.id}>
              <ReviewsSlideText>{review.content}</ReviewsSlideText>
              {review.author ? (
                <ReviewsSlideAuthor>
                  &ndash; <strong>{review.author}</strong>, {review.role}
                </ReviewsSlideAuthor>
              ) : (
                <ReviewsSlideAuthor>&ndash; {review.role}</ReviewsSlideAuthor>
              )}
            </ReviewsSlide>
          ))}
        </ReviewsSlider>
        <ReviewsSlideLink
          as="a"
          variant="mountainMeadow"
          href="https://clutch.co/profile/liki-mobile-solutions"
          target="_blank"
        >
          Check on Clutch <StyledArrowRight />
        </ReviewsSlideLink>
      </ReviewsContainer>
    </ReviewsWrapper>
  </Section>
);

export default Reviews;
