import React, { FC } from "react";

import { CloudBoxProps } from "./cloudBox.types";

import {
  CloudBoxContainer,
  CloudBoxWrapper,
  CloudBoxShape,
  CloudBoxShapePath,
  CloudBoxPriceBox,
  CloudBoxPriceBoxBoldPrice,
  CloudBoxPriceBoxAddition,
  CloudBoxContent,
  CloudBoxContentOnHover,
} from "./cloudBox.styles";

const CloudBox: FC<CloudBoxProps> = ({
  variant,
  children,
  priceBox,
  contentWithHover,
}) => (
  <CloudBoxContainer>
    <CloudBoxWrapper variant={variant}>
      <CloudBoxShape width="287" height="299" viewBox="0 0 287 299" fill="none">
        <CloudBoxShapePath
          variant={variant}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.949 25.2C219.116 25.2 240.03 43.6979 243.799 67.7986C267.923 71.5641 287 92.4577 287 117.6C287 118.068 286.993 118.535 286.98 119H287V229C287 267.66 255.66 299 217 299H80C41.3401 299 10 267.66 10 229V189C10 177.095 12.9718 165.884 18.2141 156.069C7.12504 146.817 0 133.005 0 117.6C0 92.6705 18.7727 71.9085 42.61 67.8972C42.6016 67.6676 42.6016 67.4296 42.6016 67.2C42.6016 30.1465 72.7775 0 109.867 0C133.877 0 155.488 12.5423 167.493 32.6978C175.384 27.8169 184.482 25.2 193.949 25.2Z"
        />
      </CloudBoxShape>
      <CloudBoxContent>{children}</CloudBoxContent>
      {contentWithHover && (
        <CloudBoxContentOnHover>{contentWithHover}</CloudBoxContentOnHover>
      )}
    </CloudBoxWrapper>

    <CloudBoxPriceBox>
      {priceBox.price && (
        <CloudBoxPriceBoxBoldPrice>{priceBox.price}</CloudBoxPriceBoxBoldPrice>
      )}
      {priceBox.addition && (
        <CloudBoxPriceBoxAddition>{priceBox.addition}</CloudBoxPriceBoxAddition>
      )}
      {priceBox.subscription && (
        <CloudBoxPriceBoxBoldPrice>
          {priceBox.subscription}
        </CloudBoxPriceBoxBoldPrice>
      )}
    </CloudBoxPriceBox>
  </CloudBoxContainer>
);

export default CloudBox;
