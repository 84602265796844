import styled from 'styled-components';
import { bigContentFont } from '../../theme/fonts';

import { mediaQueries as mq } from '../../theme/media';
import { SecondaryStyle } from '../button/button.styles';

import { StyledLink } from '../link/link.styles';

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

export const NavButton = styled(SecondaryStyle)`
  ${bigContentFont};
  margin-left: 0;

  ${ mq['medium'] } {
    margin-left: 19px;
  }

  ${ mq['large'] } {
    margin-left: 22px;
  }

  ${ mq['xLarge'] } {
    margin-left: 32px;
  }

  ${ mq['xmLarge'] } {
    margin-left: 36px;
  }

  ${ mq['xxsLarge'] } {
    margin-left: 42px;
  }
`;

export const NavLink = styled(StyledLink)`
  margin: 0;

  ${ mq['medium'] } {
    margin: 0 19px;
    
    &:first-child {
      margin-left: 0;
    }

    &:hover:not(:last-child) {
      color: ${({ theme }) => theme.colors.mountainMeadow};
    }
  }

  ${ mq['large'] } {
    margin: 0 22px;
  }

  ${ mq['xLarge'] } {
    margin: 0 32px;
  }

  ${ mq['xmLarge'] } {
    margin: 0 36px;
  }

  ${ mq['xxsLarge'] } {
    margin: 0 42px;
  }
`;
