import styled from 'styled-components';

import { mediaQueries as mq } from '../../theme/media';

export const StyledContactForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px ${({ theme }) => theme.sizes.layoutPadding} 40px;
  background-color: ${({ theme }) => theme.colors.mountainMeadow};
  position: relative;

  .b24-form {
    width: 100%;
  }

  ${ mq['large'] } {
    border-radius: 50px;
    width: 600px;
    max-width: 650px;
    height: auto;
  }

  ${ mq['xLarge'] } {
    width: 600px;
    height: 542px;
  }

  ${ mq['xsLarge'] } {
    width: 650px;
  }
`;
