import React, { FC } from 'react';

import { IotOakControllerSideStyles } from './iotOakController.styles';

import { IotOakControllerProps } from './iotOakController.types';

const IotOakControllerSide:FC<IotOakControllerProps> = ({ variant, children }) => (
  <IotOakControllerSideStyles variant={ variant }>
    { children }
  </IotOakControllerSideStyles>
);

export default IotOakControllerSide;
