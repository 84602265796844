import React, { FC } from 'react';

import { WhatWeDoSideStyles } from './whatWeDo.styles';

import { WhatWeDoProps } from './whatWeDo.types';

const WhatWeDoSide:FC<WhatWeDoProps> = ({ variant, children }) => (
  <WhatWeDoSideStyles variant={ variant }>
    { children }
  </WhatWeDoSideStyles>
);

export default WhatWeDoSide;
