import styled from 'styled-components';

import { SectionProps } from './section.types';

import { mediaQueries as mq } from '../../theme/media';

export const SectionStyled = styled.section<Partial<SectionProps>>`
  padding: ${({ mobilePadding = '0' }) => mobilePadding};

  ${ mq['large'] } {
    padding: ${({ padding = '0' }) => padding};
  }
`;
