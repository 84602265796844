import React, { FC } from 'react';

import useBitrixForm from '../../hooks/useBitrixForm';

import { StyledContactForm } from './contactForm.styles';

const ContactForm: FC = () => {
  const ELEMENT_ID = 'bx24_form_inline_contact_form';

  useBitrixForm(ELEMENT_ID, 'inline/31/u7br5z', 31);

  return (
    <StyledContactForm id={ELEMENT_ID} />
  );
};

export default ContactForm;
