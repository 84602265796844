import React from 'react';

import { ThemeProvider } from 'styled-components';

import theme from '../../theme/theme';
import { GlobalStyle } from '../../theme/global-styles';

import Typography from './typography/typography';
import Buttons from './buttons/buttons';
import Header from '../../components/header/header';
import LeafShape from '../../components/backgroundShape/leafShape';
import SquareShape from '../../components/backgroundShape/squareShape';
import RectangleShape from '../../components/backgroundShape/rectangleShape';
import SocialBar from '../../components/socialBar/socialBar';
import BottomBar from '../../components/bottomBar/bottomBar';

const Showcase = () => (
  <ThemeProvider theme={ theme }>
    <GlobalStyle />
    <h2>Typography</h2>
    <Typography />
    <hr/>
    <h2>Buttons</h2>
    <Buttons />
    <hr />
    <h2>Header</h2>
    <Header />
    <hr/>
    <h2>Bottom bar</h2>
    <BottomBar />
    <hr/>
    <h2>Social bar</h2>
    <SocialBar />
    <hr/>
    <h2>Background Shapes</h2>
    <LeafShape size="128px" variant="blackSqueeze" top="150px" left="200px" corner="topLeft" />
    <LeafShape size="128px" variant="mountainMeadow" top="150px" left="450px" corner="topRight" />
    <SquareShape size="256px" variant="blazeOrange" top="300px" left="150px" />
    <RectangleShape width="146px" height="99px" variant="mountainMeadow" top="-100px" left="250px" roundSide="top" fillURL="https://images.unsplash.com/photo-1607350541199-f17ea6717823?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1001&q=80" />
    <RectangleShape width="146px" height="99px" variant="mountainMeadow" top="100px" left="250px" roundSide="bottom" fillURL="https://images.unsplash.com/photo-1607350541199-f17ea6717823?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1001&q=80" />
    <hr/>
  </ThemeProvider>
);

export default Showcase;
