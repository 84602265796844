import React, { FC } from "react";

import Section from "../section/section";
import LeafShape from "../backgroundShape/leafShape";
import WhatWeDoShapes from "./whatWeDoShapes";
import WhatWeDoSide from "./whatWeDoSide";

import {
  WhatWeDoWrapper,
  WhatWeDoTitle,
  WhatWeDoText,
  WhatWeDoInline,
  WhatWeDoLeafText,
  WhatWeDoLeafContainer,
} from "./whatWeDo.styles";

import whatWeDoData from "./whatWeDo.data";

const WhatWeDo: FC = () => {
  const title = whatWeDoData.title;
  const contentArr = whatWeDoData.content;
  const leafText = whatWeDoData.leafText;

  return (
    <Section id="what-we-do" mobilePadding="120px 0 100px" padding="250px 0 0">
      <WhatWeDoWrapper>
        <WhatWeDoSide variant="withShapes">
          <WhatWeDoShapes />
        </WhatWeDoSide>
        <WhatWeDoSide>
          <WhatWeDoTitle>{title}</WhatWeDoTitle>
          {contentArr.map((content, contentID) => (
            <WhatWeDoText key={`whatWeDo-${contentID}`}>
              {content.text}
            </WhatWeDoText>
          ))}
          <WhatWeDoInline>
            <WhatWeDoLeafContainer>
              <LeafShape
                size="79px"
                variant="ebonyClay"
                corner="topLeft"
              />
            </WhatWeDoLeafContainer>
            <WhatWeDoLeafText>{leafText}</WhatWeDoLeafText>
          </WhatWeDoInline>
        </WhatWeDoSide>
      </WhatWeDoWrapper>
    </Section>
  );
};

export default WhatWeDo;
