import React, { FC } from 'react'

import CookiesHandler from '../../utils/Cookies';

import { 
  CookiesWrapper,
  CookiesContent,
  CookiesText,
  CookiesClose,
  CookieIcon
} from './cookies.styles';
import { CookiesProps } from './cookies.types';
import { COOKIES_ACCEPTED_KEY, cookiesMessage } from './cookies.data';

const Cookies: FC<CookiesProps> = ({ onCloseClick }) => {
  const handleCloseClick = () => {
    CookiesHandler.setCookie(COOKIES_ACCEPTED_KEY, true, 9999);
    onCloseClick(true);
  };
  
  return (
    <CookiesWrapper>
      <CookiesContent>
        <CookieIcon src="/svg/cookie.svg" alt="IoT Oak cookie icon" />
        <CookiesText dangerouslySetInnerHTML={{ __html: cookiesMessage }} />
        <CookiesClose onClick={ handleCloseClick }/>
      </CookiesContent>
    </CookiesWrapper>
  );
};

export default Cookies;
