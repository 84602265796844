import styled from 'styled-components';

import * as fonts from '../../../theme/fonts';

export const HeroTitle = styled.h1`
  ${fonts.heroTitleFont};
`;

export const HeroTitleLead = styled.p`
  ${fonts.heroTitleLeadFont};
`;

export const SectionBigTitle = styled.h2`
  ${fonts.sectionBigTitleFont};
`;

export const ButtonText = styled.button`
  ${fonts.buttonFont};
`;

export const SectionTitle = styled.h3`
  ${fonts.sectionTitleFont};
`;

export const ContentText = styled.p`
  ${fonts.contentFont};
`;

export const BigContentText = styled.p`
  ${fonts.bigContentFont};
`;

export const CardTitle = styled.h4`
  ${fonts.cardTitleFont};
`;

export const AuthorText = styled.p`
  ${fonts.authorFont};
`;

export const SectionLabel = styled.div`
  ${fonts.sectionLabelFont};
`

export const SmallText = styled.small`
  ${fonts.smallFont};
`;
