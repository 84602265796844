import styled from 'styled-components';

import { StyledLink } from '../link/link.styles';

import { mediaQueries as mq } from '../../theme/media';

export const LogoLink = styled(StyledLink)`
  line-height: 0;
`;

export const StyledLogo = styled.img`
  width: ${({ width = 'auto' }) => width};
  height: ${({ height = '52px' }) => height};
  
  ${ mq['large'] } {
    height: ${({ height = '64px'}) => height};
  }
`;
