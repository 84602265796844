import * as React from 'react';

import { breakpoints } from '../theme/media';

export type OnResizeType = {
  resolutionType: string;
};

export const RESOLUTION_TYPES = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  TINY_DESKTOP: 'TINY_DESKTOP',
  SMALL_DESKTOP: 'SMALL_DESKTOP',
  DESKTOP: 'DESKTOP',
  MEDIUM_DESKTOP: 'MEDIUM_DESKTOP',
  LARGE_DESKTOP: 'LARGE_DESKTOP'
};

const MATCH_MEDIA = {
  [RESOLUTION_TYPES.MOBILE]: `(max-width: ${breakpoints.small}px)`,
  [RESOLUTION_TYPES.TABLET]: `(max-width: ${breakpoints.mobileMax}px) and (min-width: ${breakpoints.small + 1}px)`,
  [RESOLUTION_TYPES.TINY_DESKTOP]: `(max-width: ${breakpoints.xsLarge}px) and (min-width: ${breakpoints.large}px)`,
  [RESOLUTION_TYPES.SMALL_DESKTOP]: `(max-width: ${breakpoints.xxLarge}px) and (min-width: ${
    breakpoints.xsLarge + 1
  }px)`,
  [RESOLUTION_TYPES.DESKTOP]: `(max-width: ${breakpoints.xxsLarge}px) and (min-width: ${breakpoints.xxLarge + 1}px)`,
  [RESOLUTION_TYPES.MEDIUM_DESKTOP]: `(max-width: ${breakpoints.xxxLarge}px) and (min-width: ${
    breakpoints.xxsLarge + 1
  }px)`,
  [RESOLUTION_TYPES.LARGE_DESKTOP]: `(min-width: ${breakpoints.xxxLarge + 1}px)`
};

const MEDIA_TO_RESOLUTION = {
  [MATCH_MEDIA.MOBILE]: RESOLUTION_TYPES.MOBILE,
  [MATCH_MEDIA.TABLET]: RESOLUTION_TYPES.TABLET,
  [MATCH_MEDIA.TINY_DESKTOP]: RESOLUTION_TYPES.TINY_DESKTOP,
  [MATCH_MEDIA.SMALL_DESKTOP]: RESOLUTION_TYPES.SMALL_DESKTOP,
  [MATCH_MEDIA.DESKTOP]: RESOLUTION_TYPES.DESKTOP,
  [MATCH_MEDIA.MEDIUM_DESKTOP]: RESOLUTION_TYPES.MEDIUM_DESKTOP,
  [MATCH_MEDIA.LARGE_DESKTOP]: RESOLUTION_TYPES.LARGE_DESKTOP
};

const useOnResize = (): OnResizeType => {
  const [resolutionType, setResolutionType] = React.useState<string>(RESOLUTION_TYPES.DESKTOP);

  const onMediaChange = React.useCallback(({ media, matches }) => {
    if (MEDIA_TO_RESOLUTION.hasOwnProperty(media) && matches) {
      setResolutionType(MEDIA_TO_RESOLUTION[media]);
    }
  }, []);

  React.useEffect(() => {
    const mediaHandlers = Object.values(MATCH_MEDIA).map((singleMedia) => ({
      handler: window.matchMedia(singleMedia),
      media: singleMedia
    }));

    mediaHandlers.forEach(({ handler, media }) => {
      if (handler.matches && MEDIA_TO_RESOLUTION.hasOwnProperty(media)) {
        setResolutionType(MEDIA_TO_RESOLUTION[media]);
      }

      try {
        handler.addEventListener('change', onMediaChange);
      } catch (e) {
        handler.addListener(onMediaChange);
      }
    });

    return () => {
      mediaHandlers.forEach(({ handler }) => {
        try {
          handler.removeEventListener('change', onMediaChange);
        } catch (e) {
          handler.removeListener(onMediaChange);
        }
      });
    };
  }, [onMediaChange]);

  return {
    resolutionType
  };
};

export default useOnResize;
